export const theme = {
  fontSizes: {
    desktop: {
      headerL: "",
      headline: "",
      title: "",
      body: "",
      footer: "",
    },
    tablet: {
      headerL: "",
      headline: "",
      title: "",
      body: "",
      footer: "",
    },
    mobile: {
      headerXxxxl: "64px",
      headerXxxl: "54px",
      headerXxl: "45px",
      headerXl: "38px",
      headerL: "32px",
      header: "26px",
      headerSm: "22px",
      title: "20px",
      subheader: "17px",
      body: "14px",
      footer: "12px",
      footerSm: "10px",
    },
  },
  lineHeight: {
    headerXxxxl: "normal",
    headerXxxl: "normal",
    headerXxl: "normal",
    headerXl: "normal",
    headerL: "normal",
    header: "normal",
    headerSm: "normal",
    title: "1.5",
    subheader: "1.5",
    body: "1.5",
    footer: "1.5",
    footerSm: "1.5",
  },
  letterSpacing: {
    headerXxxxl: `0.04em`,
    headerXxxl: `0.04em`,
    headerXxl: `0.04em`,
    headerXl: `0.04em`,
    headerL: `0.04em`,
    header: `0.04em`,
    headerSm: `0.04em`,
    title: "normal",
    subheader: "normal",
    body: "normal",
    footer: "normal",
    footerSm: "normal",
  },
  fontStyles: {
    suisse: "Suisse Intl",
    druk: "Druk",
    drukWide: "Druk Wide",
  },
  colors: {
    green: "#6EDBBB",
    white: "#F7F7F7",
    offWhite: "rgba(247, 247, 247, 0.6)",
    greyBtn: "#F7F7F71F",
    blackTransparent: "#11111F33",
    blue: "#25254399",
    blueSolid: "#18182C",
    black: "#11111F",
    blueShade:
      "linear-gradient(90deg,rgba(37, 37, 67, 0.6) 0%,rgba(37, 37, 67, 0.36) 100%)",
    lightBlueShade:
      "linear-gradient(90deg, rgba(37, 37, 67, 0.6) 0%, rgba(37, 37, 67, 0.36) 100%)",
    background: "#11111F",
    modalBg: "rgba(17, 17, 31, 0.6)",
    glass:
      "linear-gradient(122.72deg, rgba(180, 180, 180, 0.2) 0%, rgba(79, 79, 79, 0.2) 1.75%, rgba(255, 255, 255, 0.2) 33.05%, rgba(175, 175, 175, 0.2) 97.16%)",
    glass2:
      "linear-gradient(122.72deg, rgba(180, 180, 180, 0.1) 1.74%, rgba(79, 79, 79, 0.1) 1.75%, rgba(255, 255, 255, 0.1) 33.05%, rgba(175, 175, 175, 0.1) 97.16%)",
    glassDark:
      "linear-gradient(122.5deg, rgba(180, 180, 180, 0.1) -11.54%, rgba(79, 79, 79, 0.1) -11.53%, rgba(255, 255, 255, 0.1) 24.12%, rgba(175, 175, 175, 0.1) 97.15%)",
    backgroundTint: "#161629",
    brandShiny: "rgba(66, 251, 173, 1)",
    ephemeralBg: "#252543",
    ephemeralBgTransparent: "#25254366",
    grey: "#8B8B96",
    searchGrey: "#2C2C3D",
    grey2: "rgba(44, 44, 61, 1)",
    red: "#FC6894",
    purple: "rgba(89, 93, 237, 1)",
    softWhite: "#F7F7F7",
    softGreen: "#6EDBBB",
    lineColor: "rgba(230, 230, 230, 0.7)",
    MenuButton: "rgba(226, 226, 228, 0.6)",
    checkInButton: "rgba(255, 255, 255, 0.12)",
    border: "rgba(255, 255, 255, 0.15)",
    backgroundOverlay:
      "linear-gradient(rgba(4, 15, 11, 0) 0%, rgba(4, 15, 11, 0.27) 50.52%, rgba(4, 15, 11, 0.72) 84.38%)",
    backgroundGradient:
      "linear-gradient(180deg, rgba(37, 37, 67, 0.3) 0%, rgba(37, 37, 67, 0.18) 100%)",
    borderGradient:
      "linear-gradient(155deg, rgba(66, 251, 173, 1), rgba(78, 77, 225, 1), rgba(111, 6, 48, 1))",
    reverseBorderGradient:
      "linear-gradient(-155deg, rgba(66, 251, 173, 1), rgba(78, 77, 225, 1), rgba(111, 6, 48, 1))",
    translucentBorderGradient:
      "linear-gradient(155deg, rgba(66, 251, 173, 0.5), rgba(78, 77, 225, 0.5), rgba(111, 6, 48, 0.5))",
    blueGradientBg:
      "linear-gradient(270.05deg, rgba(37, 37, 67, 0.48) -1.27%,rgba(37, 37, 67, 0.8) 99.96%)",
    grayGradientBg: `linear-gradient(122.72deg, rgba(180, 180, 180, 0.1) 1.74%, rgba(79, 79, 79, 0.1) 1.75%, rgba(255, 255, 255, 0.1) 33.05%, rgba(175, 175, 175, 0.1) 97.16%)`,
    quickSearchBlue: `linear-gradient(225deg,
      hsl(218deg 55% 13%) 0%,
      hsl(217deg 54% 15%) 10%,
      hsl(215deg 54% 17%) 20%,
      hsl(213deg 55% 19%) 30%,
      hsl(212deg 57% 22%) 40%,
      hsl(210deg 58% 24%) 50%,
      hsl(201deg 82% 22%) 60%,
      hsl(195deg 100% 21%) 70%,
      hsl(191deg 100% 22%) 80%,
      hsl(187deg 90% 23%) 90%,
      hsl(182deg 59% 28%) 100%
    )`,
    quickSearchRed: `linear-gradient(
      225deg,
      hsl(338deg 58% 10%) 0%,
      hsl(336deg 56% 13%) 10%,
      hsl(335deg 55% 15%) 20%,
      hsl(333deg 55% 18%) 30%,
      hsl(331deg 54% 20%) 40%,
      hsl(330deg 54% 23%) 50%,
      hsl(324deg 55% 25%) 60%,
      hsl(318deg 55% 27%) 70%,
      hsl(312deg 53% 29%) 80%,
      hsl(304deg 50% 30%) 90%,
      hsl(293deg 48% 34%) 100%
    )`,
    quickSearchPurple: `linear-gradient(
      225deg,
      hsl(270deg 48% 13%) 0%,
      hsl(268deg 47% 16%) 10%,
      hsl(266deg 46% 20%) 20%,
      hsl(264deg 46% 24%) 30%,
      hsl(262deg 45% 28%) 40%,
      hsl(260deg 44% 32%) 50%,
      hsl(256deg 41% 35%) 60%,
      hsl(251deg 38% 39%) 70%,
      hsl(247deg 36% 43%) 80%,
      hsl(242deg 34% 46%) 90%,
      hsl(238deg 33% 49%) 100%
    )`,
    quickSearchGreen: `linear-gradient(
      225deg,
      hsl(171deg 64% 17%) 0%,
      hsl(169deg 67% 18%) 10%,
      hsl(167deg 69% 18%) 20%,
      hsl(165deg 70% 19%) 30%,
      hsl(162deg 69% 19%) 40%,
      hsl(160deg 67% 20%) 50%,
      hsl(157deg 67% 22%) 60%,
      hsl(153deg 65% 24%) 70%,
      hsl(149deg 60% 27%) 80%,
      hsl(144deg 55% 30%) 90%,
      hsl(138deg 50% 34%) 100%
    )`,
    onboardingGreen: "#35615B",
    onboardingBlue: "#2D2E6F",
    onboardingPink: "#6D324A",
    onboardingSoftBlue: "#344F78",
    onboardingTextColor: "#ADBEB9",
    newBtn: "#2D2D52",
  },
};
