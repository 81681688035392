import PropTypes from "prop-types";
import { FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { useBuyTicketStore } from "../buyTicketStore";
import { useMemo } from "react";

const { colors } = theme;

export default function TicketsStatus({ status, isLoading }) {
  const { maxCount, userMints, soldOut, userTickets } = status || {};
  const { ticketsSelected } = useBuyTicketStore();

  const remaining = useMemo(() => {
    const totalRemainingTickets = userTickets?.reduce(
      (acc, ticket) => acc + ticket.remaining,
      0
    );

    if (maxCount <= totalRemainingTickets) {
      return maxCount;
    }

    if (totalRemainingTickets <= userMints) {
      return totalRemainingTickets;
    }
    return totalRemainingTickets - userMints;
  }, [userTickets, userMints, maxCount]);

  const isMaxQuantity = ticketsSelected.length >= remaining;

  if (isLoading) {
    return <SkeletonElement height="18px" width="30%" />;
  }

  if (soldOut) {
    return <FooterText color={colors.red}>Sold out!</FooterText>;
  }

  if (userMints >= maxCount) {
    return (
      <FooterText color={colors.red}>
        {"You've reached the limit bought"}
      </FooterText>
    );
  }

  return (
    <FooterText color={isMaxQuantity ? colors.green : colors.grey}>
      {ticketsSelected.length}/{remaining} selected
    </FooterText>
  );
}

TicketsStatus.propTypes = {
  status: PropTypes.object,
  perAccountMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoading: PropTypes.bool,
};
