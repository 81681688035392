import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useMiloStore = create(
  persist(
    (set) => ({
      isQuickMessage: false,
      setIsQuickMessage: (value) => set(() => ({ isQuickMessage: value })),

      sessionId: "",
      setSessionId: (value) => set(() => ({ sessionId: value })),

      messages: [],
      setMessages: (value) => set(() => ({ messages: value })),

      isInitialMessageSent: false,
      setIsInitialMessageSent: (value) =>
        set(() => ({ isInitialMessageSent: value })),
    }),
    {
      name: "milo-storage",
    }
  )
);
