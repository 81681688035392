import PropTypes from "prop-types";
import { ButtonRectSm, FooterText } from "../../GlobalStyles";
import Bookmark from "../../assets/svg/bookmark.svg?react";
import Wallet from "../../assets/svg/wallet.svg?react";
import Ticket from "../../assets/svg/ticket.svg?react";
import { useSearchParams } from "react-router-dom";

export const QuickChat = ({
  message,
  isQuickMessage,
  onSelect,
  isYesOrNo = false,
}) => {
  const [searchParams] = useSearchParams();
  const suggestion = searchParams.get("suggestion");

  const quickMessages = [
    {
      text: "Find an event",
      icon: <Bookmark stroke="white" height={14} width={14} />,
    },
    {
      text: "Check balance",
      icon: <Wallet stroke="white" height={14} width={14} />,
    },
    {
      text: "Transfer tickets",
      icon: <Ticket stroke="white" height={16} width={16} />,
    },
  ];

  const yesOrNoMessages = [
    {
      text: "Yes",
      icon: null,
    },
    {
      text: "No",
      icon: null,
    },
  ];

  let messages = isYesOrNo ? yesOrNoMessages : quickMessages;

  if (suggestion && message?.trigger === "events_quick_chat") return null;

  if (
    message?.events ||
    message?.trigger === "balance_tool" ||
    message?.trigger === "quick_chat" ||
    message?.content?.toLowerCase().includes("correct?") ||
    message?.content?.includes("Hey there! I'm Milo. How can I help you?") ||
    (!suggestion && message?.trigger === "events_quick_chat") ||
    message?.isTransferSuccess ||
    !isQuickMessage
  )
    return (
      <div
        style={{
          position: "relative",
          left: "50%",
          right: "50%",
          marginLeft: "-50vw",
          marginRight: "-50vw",
          width: "100vw",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center",
            gap: 16, // Spacing between grid items
            padding: "0 40px", // Padding on both sides
            margin: "0 auto", // Center the container
            overflowX: "auto", // Allow horizontal scrolling if needed
            height: "100%",
          }}
        >
          {messages.map((msg, index) => (
            <ButtonRectSm
              key={index}
              primary
              onClick={() => onSelect({ message: msg.text })}
              style={{
                minWidth: isYesOrNo ? 80 : "auto",
              }}
            >
              {msg?.icon ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                  }}
                >
                  {msg.icon}
                </div>
              ) : null}
              <FooterText
                style={{
                  textWrap: "nowrap",
                }}
              >
                {msg.text}
              </FooterText>
            </ButtonRectSm>
          ))}
        </div>
      </div>
    );
};

QuickChat.propTypes = {
  message: PropTypes.object,
  isQuickMessage: PropTypes.bool,
  onSelect: PropTypes.func,
  isYesOrNo: PropTypes.bool,
};
