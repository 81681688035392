import { Flex, Group, Image, Stack, Text } from "@mantine/core";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useGetEventTickets } from "../../routes/Event/hooks/eventQueries";
import BuyTicketButton from "../BuyTicket/BuyTicketButton";
import { EarnMore } from "./EarnMore";
import { GlassmorphPlaceholder } from "../../constants/constants";
import { useTicketContract } from "../PromotionPool/hooks/promotionPoolHooks";
import { useState } from "react";
import TicketLink from "./components/TicketLink";

export const EventBox = ({
  id,
  artistName,
  headlineArtistId,
  headlineArtist,
  venue,
  date,
  imageUrl,
  isDrawer = false,
  isPromotionPool = false,
  urls,
}) => {
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format date part
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "short", // "Sat"
      month: "short", // "Nov"
      day: "numeric", // "23"
      year: "numeric", // "2024"
    });

    // Format time part
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, "0")}${period}`;

    return { date: formattedDate, time: formattedTime };
  };

  const link = `/events/${id}?date=${date}`;
  const { date: formattedDate, time } = formatDate(date);
  const { data: ticketsData, isLoading: isTicketsLoading } = useGetEventTickets(
    {
      artistId: headlineArtistId,
      eventId: id,
    }
  );

  const handleRedirect = () => {
    if (isPromotionPool || !isDrawer) navigate(link);
  };

  const { data: ticketContract } = useTicketContract({ eventId: id });
  const contract = ticketContract?.data?.[0] || {};

  // Calculate the number of items to show based on the state
  const visibleUrls = urls
    ? showAll
      ? Object.entries(urls)
      : Object.entries(urls).slice(0, 3)
    : null;

  return (
    <Stack
      style={{
        backgroundColor: isDrawer ? "none" : "#232428",
        padding: isPromotionPool ? 0 : "8px 12px",
        borderRadius: "12px",
        cursor: "pointer",
        minWidth: "237px",
        maxWidth: "245px",
        width: "100%",
        flexDirection: "column",
        gap: 12,

        minHeight: "80px",
      }}
    >
      <Flex
        style={{
          gap: 12,
          overflow: "hidden",
        }}
        onClick={handleRedirect}
      >
        <Image
          src={imageUrl ?? contract?.image ?? GlassmorphPlaceholder}
          alt={artistName}
          style={{ borderRadius: "10px", width: "58px", height: "58px" }}
          fit="cover"
          onError={(e) => (e.target.src = GlassmorphPlaceholder)}
        />
        <Stack
          gap={0}
          style={{
            overflow: "hidden",
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: "14px",
              fontWeight: 600,
              fontFamily: "Suisse Intl",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: "140px",
              marginBottom: 2,
            }}
            title={artistName}
          >
            {artistName}
          </Text>
          <Text
            style={{
              color: "#F7F7F7",
              fontSize: "12px",
              fontFamily: "Suisse Intl",
              fontWeight: 400,
              opacity: 0.6,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            title={venue}
          >
            {venue}
          </Text>
          <Flex>
            <Text
              style={{
                color: "#6EDBBB",
                fontSize: "12px",
                fontFamily: "Suisse Intl",
                fontWeight: 400,

                textWrap: "nowrap",
              }}
            >
              {formattedDate}
            </Text>
            <Text
              span
              style={{
                color: "#F7F7F7",
                fontSize: "12px",
                fontFamily: "Suisse Intl",
                fontWeight: 400,
                opacity: 0.6,
                marginLeft: "6px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {time}
            </Text>
          </Flex>
        </Stack>
      </Flex>
      {!isDrawer && (
        <>
          {ticketsData?.tickets?.length > 0 && (
            <Flex
              style={{
                gap: 12,
                justifyContent: "space-between",
              }}
            >
              <BuyTicketButton
                event={{
                  id,
                  event_name: artistName,
                  headline_artist_id: headlineArtistId,
                  headline_artist: headlineArtist,
                  venue,
                  date,
                  imageUrl,
                }}
                tickets={ticketsData}
                isLoading={isTicketsLoading}
                fromMilo
              />
              {(contract.promoter_pool_percentage ?? 0) > 0 && (
                <EarnMore
                  event={{
                    id,
                    artistName,
                    headlineArtistId,
                    venue,
                    date,
                    imageUrl,
                  }}
                  isEventCard={true}
                />
              )}
            </Flex>
          )}
          {visibleUrls && visibleUrls.length > 0 && (
            <Group
              style={{
                rowGap: 12,
              }}
            >
              {visibleUrls?.map(([type, url], index) => (
                <TicketLink key={index} eventId={id} type={type} url={url} />
              ))}
            </Group>
          )}
          {urls && Object.entries(urls).length > 3 && (
            <Text
              onClick={() => setShowAll(!showAll)}
              style={{
                fontFamily: "Suisse Intl",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                textAlign: "left",
                textDecorationSkipInk: "none",
                color: "#99999D",
              }}
            >
              {showAll ? "See Less" : "See More"}
            </Text>
          )}
        </>
      )}
    </Stack>
  );
};

EventBox.propTypes = {
  id: PropTypes.number,
  artistName: PropTypes.string,
  headlineArtistId: PropTypes.any,
  headlineArtist: PropTypes.string,
  venue: PropTypes.string,
  date: PropTypes.string,
  imageUrl: PropTypes.string,
  isDrawer: PropTypes.bool,
  isPromotionPool: PropTypes.bool,
  urls: PropTypes.object,
};
