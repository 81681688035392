import { useWertWidget } from "@wert-io/module-react-component";
import { theme } from "../../../utils/theme";
import { USDC_ADDRESS, WERT_TEST_TOKEN } from "../../../constants/constants";
import useAuth from "../../../hooks/useAuth";
import { encodeFunctionData } from "viem";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import { useGetTicketManagerABI } from "./checkoutQueries";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { useCheckoutStore } from "../checkoutStore";
import {
  addDelayInMs,
  countAndDeduplicateTickets,
  processTicketMinting,
  setPendingState,
  sortItems,
} from "../../../utils/checkout";
import { useGetWertKey } from "../../../routes/Ticket/ticketQueries";
import { useEffect } from "react";
import PaymentSuccess from "../PaymentSuccess";
import { isProd, setDelay } from "../../../utils/utils";
import { deployMetadata } from "../../../api/ticketing";
import moment from "moment";
import ProcessingWertModal from "../../Modal/ProcessingWertModal";
import ErrorModal from "../../Modal/ErrorModal";
import { useSearchParams } from "react-router-dom";

const { colors } = theme;

const options = {
  // PUT YOUR PARTNER ID HERE
  partner_id: import.meta.env.VITE_WERT_PARTNER_ID,
  is_crypto_hidden: true,
  origin: isProd ? "https://widget.wert.io" : "https://sandbox.wert.io",
  extra: {
    item_info: {
      author: "Momentify",
      author_image_url: "https://cdn.momentify.xyz/test/image.png",
      image_url: null,
      name: null,
      category: "Ticket",
    },
  },
  color_background: colors.black,
  color_buttons: colors.green,
  buttons_border_radius: 12,
  color_buttons_text: colors.black,
  color_links: colors.green,
};

const smartContractOptions = {
  address: null,
  commodity: isProd ? "USDC" : "TT",
  network: isProd ? "base" : "base_sepolia",
  commodity_amount: 0, // Amount of total in USDC
  sc_address: import.meta.env.VITE_TICKETS_MANAGER_ADDRESS, // Ticket Manager Contract Address
  sc_input_data: null,
  // PUT YOUR PRIVATE KEY HERE
  private_key: "", // We advise you not to store the private key on the frontend
};

export const useWertCheckout = () => {
  const { data: wertKey } = useGetWertKey();
  const { ticketsSelected, contract, event, resetTicketsSelected, isModal } =
    useBuyTicketStore();
  const {
    setIsMinting,
    setTxnHash,
    setPendingTxn,
    setPendingEvent,
    setError,
    total,
    setBoughtTickets,
    setPendingBoughtTickets,
    setWertClosed,
    resetPending,
    deployingMetadata,
    setDeployingMetadata,
    resetTotal,
    setIsMiloBuySuccess,
  } = useCheckoutStore();
  const { data: ticketManagerABI } = useGetTicketManagerABI();
  const {
    setSheetModalContent,
    toggleSheetModal,
    closeSheetModal,
    setSheetModalBackdropStyle,
  } = useGlobalModal();
  const { loggedInUser } = useAuth();
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("referralCode");

  const { contractAddress, image, symbol } = contract || {};
  const {
    id: event_id,
    headline_artist_id,
    headline_artist,
    event_name,
    venue,
    date: event_date,
  } = event || {};
  const userWallet = loggedInUser?.receiving_wallet;

  useEffect(() => {
    if (!wertKey) return;

    // eslint-disable-next-line no-undef
    const key = Buffer.from(wertKey, "base64").toString("utf8");

    smartContractOptions.private_key = key;
  }, [wertKey]);

  const handleStatus = async (payment) => {
    const { tx_id, status } = payment || {};

    let isMetadataDeploying =
      deployingMetadata ||
      JSON.parse(localStorage.getItem("checkout")).state.deployingMetadata;

    if (status === "pending" && !isMetadataDeploying) {
      setIsMinting(true);
      setSheetModalBackdropStyle({});
      setSheetModalContent(<ProcessingWertModal />);
      toggleSheetModal();
      console.log("Deploying metadata...");

      setDeployingMetadata(true);

      const sortedTickets = sortItems(ticketsSelected);

      await deployMetadata({
        metadata: {
          artistId: headline_artist_id,
          eventId: event_id,
          contractAddress: contractAddress,
          name: event_name,
          symbol: symbol,
          image: image,
          description: `Ticket for ${event_name} by ${headline_artist}`,
          attributes: sortedTickets.map(({ type }) => {
            return {
              data: [
                {
                  trait_type: "Event name",
                  value: event_name,
                },
                {
                  trait_type: "Artist name",
                  value: headline_artist,
                },
                {
                  trait_type: "Venue name",
                  value: venue,
                },
                {
                  trait_type: "Date",
                  value: moment(event_date).format("Do MMM YYYY"),
                },
                {
                  trait_type: "Event start time",
                  value: moment(event_date).format("HH:mm"),
                },
                {
                  trait_type: "Ticket type",
                  value: type,
                },
                {
                  trait_type: "User id",
                  value: loggedInUser?.id,
                },
              ],
            };
          }),
        },
      }).then(() => {
        console.log("Metadata deployed");
      });
    }

    if (!tx_id) return;

    const boughtTickets = countAndDeduplicateTickets(ticketsSelected);

    const stateUpdaters = {
      setTxnHash,
      setBoughtTickets,
      setIsMinting,
      setPendingBoughtTickets,
      setPendingTxn,
      setPendingEvent,
      setError,
    };

    // Handle pending status
    if (status === "pending") {
      await setPendingState(event, boughtTickets, tx_id, stateUpdaters);
      await setDelay(1000);
    }

    // Process minting
    const mintingResult = await processTicketMinting(
      {
        tx_id,
        userWallet,
        contractAddress,
        boughtTickets,
        loggedInUser,
        referralCode,
      },
      stateUpdaters
    ).catch((error) => {
      console.error("Failed to process minting:", error);
      return { success: false };
    });

    if (mintingResult.success) {
      closeSheetModal();

      setSheetModalBackdropStyle({});
      await addDelayInMs(1000);

      setSheetModalContent(<PaymentSuccess />);
      toggleSheetModal();

      isModal && setIsMiloBuySuccess(true);
      return;
    }

    // Proccess Minting if ever it wasnt added successful in pending state
    if (status === "success") {
      const mintingResult = await processTicketMinting(
        {
          tx_id,
          userWallet,
          contractAddress,
          boughtTickets,
          loggedInUser,
          referralCode,
        },
        stateUpdaters
      ).catch((error) => {
        console.error("Failed to process minting:", error);
        return { success: false };
      });

      if (mintingResult.success) {
        closeSheetModal();
        setSheetModalBackdropStyle({});
        await addDelayInMs(1000);

        setSheetModalContent(<PaymentSuccess />);
        toggleSheetModal();

        isModal && setIsMiloBuySuccess(true);
      }
    }
  };

  const handleClose = () => {
    setIsMinting(false);
    setSheetModalBackdropStyle({});

    console.log("Wert closed.");

    const checkout = localStorage.getItem("checkout");
    const txn = checkout && JSON.parse(checkout).state.txnHash;
    const pendingEvent = checkout && JSON.parse(checkout).state.pendingEvent;
    const pendingTxn = checkout && JSON.parse(checkout).state.pendingTxn;
    const pendingTickets =
      checkout && JSON.parse(checkout).state.pendingBoughtTickets;

    if (pendingTxn && !txn) {
      setWertClosed(true);
      setPendingEvent(pendingEvent);
      setPendingTxn(pendingTxn);
      setPendingBoughtTickets(pendingTickets);
    }

    setDeployingMetadata(false);
    resetTicketsSelected();
    resetTotal();
  };

  const handleError = async (error) => {
    console.log("error", error);

    setIsMinting(false);
    setError("Something went wrong. Please try again later.", error);

    closeSheetModal();
    setSheetModalBackdropStyle({});
    await addDelayInMs(1000);

    setSheetModalContent(<ErrorModal />);
    toggleSheetModal();
  };

  const { open: openWertWidget } = useWertWidget({
    theme: "dark",
    listeners: {
      loaded: () => console.log("loaded"),
      "payment-status": (payment) => handleStatus(payment),
      error: handleError,
      close: () => handleClose(),
    },
  });

  const buyWithCard = async () => {
    if (
      !smartContractOptions.private_key ||
      !options.partner_id ||
      !userWallet ||
      !event ||
      !contract
    )
      return;

    try {
      setIsMinting(true);

      const tickets = countAndDeduplicateTickets(ticketsSelected).map(
        ({ tier_id, type, count }) => ({
          tierName: type,
          tierId: tier_id,
          numTokens: count,
        })
      );

      // Input Data
      const inputData = encodeFunctionData({
        abi: ticketManagerABI,
        functionName: "batchMint",
        args: [
          contractAddress, // Tickets Contract
          userWallet, // Wallet Address of User
          tickets, // Selected Tickets
          isProd ? USDC_ADDRESS : WERT_TEST_TOKEN, // USDC or Wert Test Token
        ],
      });

      smartContractOptions.sc_input_data = inputData;

      // Set event name
      options.extra.item_info.name = headline_artist ?? event_name;

      // Set event image
      if (contract?.image) options.extra.item_info.image_url = contract.image;

      // Set address to user's wallet
      smartContractOptions.address = userWallet;

      // Set amount of commodities
      smartContractOptions.commodity_amount = total;

      openWertWidget({ options, smartContractOptions });
      setBoughtTickets([]);
      setTxnHash(null);
      resetPending();
      toggleSheetModal();
    } catch (error) {
      console.error(error);
      setIsMinting(false);
      setError(error);
    }
  };

  return { buyWithCard };
};
