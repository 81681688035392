import { toast } from "react-toastify";
import { BodyText, ButtonRect, ButtonRectSm } from "../../../GlobalStyles";
import Ticket from "../../../assets/svg/ticket.svg?react";
import TwoTicketIcon from "../../../assets/svg/two-tickets.svg?react";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
import useGlobalModal from "../../../hooks/useGlobalModal";
import BuyTicket from "../index";
import { useBuyTicketStore } from "../buyTicketStore";
import { useMemo } from "react";
import PropTypes from "prop-types";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export default function BuyTicketButton({
  event,
  tickets,
  isLoading,
  fromMilo,
}) {
  const { tickets: directTickets } = tickets || {};

  const { loggedInUser, login } = useMomentifyLogin();
  const { setSheetModalContent, toggleSheetModal, setSheetModalBackdropStyle } =
    useGlobalModal();
  const { setIsOpen, setIsModal } = useBuyTicketStore();

  const { lowestPrice, highestPrice } = useMemo(() => {
    if (!directTickets || !directTickets?.length) {
      return { lowestPrice: 0, highestPrice: 0 };
    }

    return directTickets.reduce(
      (acc, ticket) => {
        if (ticket.price < acc.lowestPrice) acc.lowestPrice = ticket.price;
        if (ticket.price > acc.highestPrice) acc.highestPrice = ticket.price;
        return acc;
      },
      { lowestPrice: Infinity, highestPrice: -Infinity }
    );
  }, [directTickets]);

  const handleOpenDirectTicket = (e) => {
    e.preventDefault();

    if (!directTickets || !directTickets?.length) return;

    if (!loggedInUser) return login();

    const tiers = directTickets?.filter((ticket) => ticket?.tier_id);

    if (tiers?.length !== directTickets?.length) {
      return toast.error("You can't buy old tickets");
    }

    if (fromMilo) {
      setSheetModalContent(
        <BuyTicket event={event} ticketsData={tickets} isModal />
      );
      setSheetModalBackdropStyle({
        backdropFilter: "blur(10px)",
      });
      toggleSheetModal();
      setIsModal(true);
    }

    setIsOpen(true);
  };

  if (isLoading) {
    return <SkeletonElement height="32px" width="110px" radius="10px" />;
  }

  if (!directTickets || !directTickets?.length) {
    return (
      <ButtonRectSm
        primary
        onClick={handleOpenDirectTicket}
        style={{ padding: "12px 16px", gap: "8px" }}
        disabled
      >
        <Ticket stroke="white" height={16} width={16} />
        No tickets
      </ButtonRectSm>
    );
  }

  if (fromMilo) {
    return (
      <ButtonRectSm
        primary
        onClick={handleOpenDirectTicket}
        style={{ padding: "12px 16px", gap: "8px" }}
      >
        <Ticket stroke="white" height={16} width={16} />
        <BodyText weight="600" style={{ textWrap: "nowrap" }}>
          {lowestPrice === 0 && highestPrice === 0
            ? "Free"
            : `$${lowestPrice} - $${highestPrice}`}
        </BodyText>
      </ButtonRectSm>
    );
  }

  return (
    <ButtonRect
      primary
      onClick={handleOpenDirectTicket}
      style={{ padding: "12px 16px", gap: "8px" }}
    >
      <TwoTicketIcon stroke="white" height={16} width={16} />
      <BodyText weight="600" style={{ textWrap: "nowrap" }}>
        {lowestPrice === 0 && highestPrice === 0
          ? "Free"
          : `$${lowestPrice} - $${highestPrice}`}
      </BodyText>
    </ButtonRect>
  );
}

BuyTicketButton.propTypes = {
  event: PropTypes.object,
  tickets: PropTypes.object,
  isLoading: PropTypes.bool,
  fromMilo: PropTypes.bool,
};
