import { Group, Image, Text } from "@mantine/core";
import PropTypes from "prop-types";
import { PromoteToEarnDrawer } from "./components/PromoteToEarnDrawer";
import { useDisclosure } from "@mantine/hooks";

export const EarnMore = ({ event, isEventCard = false }) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <PromoteToEarnDrawer opened={opened} close={close} event={event} />
      <Group
        gap={8}
        style={{
          backgroundColor: !isEventCard ? "#232428" : "transparent",
          color: "white",
          fontFamily: "Suisse Intl",
          fontWeight: "600",
          fontSize: "12px",
          height: "32px",
          borderRadius: "8px",
          width: "fit-content",
          minWidth: "auto",
          whiteSpace: "nowrap",
          flex: "0 0 auto",
          padding: isEventCard ? 0 : "6px 16px",
          marginLeft: "auto",
          marginRight: !isEventCard ? "auto" : "0",
        }}
        onClick={(e) => {
          e.preventDefault();
          open();
        }}
      >
        <Image
          src="/images/milo/earn.svg"
          alt="Earn more"
          style={{
            position: "relative",
            bottom: 2,
          }}
        />
        {isEventCard ? (
          <Text
            style={{
              fontFamily: "Suisse Intl",
              fontSize: "12px",
              fontWeight: "600",
              lineHeight: "100%",
              textAlign: "center",
              textDecorationLine: "underline",
              textDecorationStyle: "solid",
              textDecorationSkipInk: "auto",
              color: "#FFFFFF",
            }}
          >
            Earn!
          </Text>
        ) : (
          <Text
            style={{
              //styleName: Footer bold 100 LH;
              fontFamily: "Suisse Intl",
              fontSize: "12px",
              fontWeight: "600",
              lineHeight: "100%",
              textAlign: "center",
              textDecorationSkipInk: "none",
              color: "#FFFFFF",
            }}
          >
            Tell me more!
          </Text>
        )}
      </Group>
    </>
  );
};

EarnMore.propTypes = {
  event: PropTypes.object,
  isEventCard: PropTypes.bool,
};
