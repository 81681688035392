import useGeolocation from "../../hooks/useGeolocation";
import {
  useFutureLondonEvents,
  useFutureNearbyEvents,
  useLondonEvents,
  useNearbyEvents,
} from "../../routes/Events/hooks/eventsQueries";
import useAuth from "../../hooks/useAuth";
import { SkeletonElements } from "./components/SkeletonElements";
import { SmallHeader } from "./components/SmallHeader";
import { EventCard } from "./components/EventCard";
import { getDistanceFromLatLong } from "../../utils/utils";
import { useEffect, useMemo } from "react";
import useCheckInModalToggle from "../../hooks/useCheckInModalToggle";
import withCheckInContextProvider from "../../hoc/withCheckInContextProvider";
import PropTypes from "prop-types";
function NearYou({ setLocalEvent }) {
  const { currentLocation } = useGeolocation();
  const { loggedInUser } = useAuth();

  const nearbyEventsQuery = useNearbyEvents({
    latitude: currentLocation?.coords?.latitude,
    longitude: currentLocation?.coords?.longitude,
    userId: loggedInUser?.id ?? "",
  });

  const londonEventsQuery = useLondonEvents();

  const futureNearbyEventsQuery = useFutureNearbyEvents({
    latitude: currentLocation?.coords?.latitude,
    longitude: currentLocation?.coords?.longitude,
    userId: loggedInUser?.id ?? "",
  });

  const futureLondonEventsQuery = useFutureLondonEvents();

  const headerText =
    nearbyEventsQuery?.data?.length > 0 ||
    futureNearbyEventsQuery?.data?.length > 0
      ? "Near you within"
      : "Near London within";
  const data =
    nearbyEventsQuery?.data?.length > 0
      ? nearbyEventsQuery?.data
      : londonEventsQuery?.data;

  const { isOpen } = useCheckInModalToggle();
  // Sort events based on distance
  useEffect(() => {
    const events = data;
    if (isOpen) return;

    if (currentLocation && events && events?.length) {
      const { latitude: lat1, longitude: lon1 } = currentLocation.coords;
      const sortedEvents = [...events].sort((a, b) => {
        const distanceA = getDistanceFromLatLong(
          lat1,
          lon1,
          a?.geometry?.coordinates?.[1],
          a?.geometry?.coordinates?.[0]
        );
        const distanceB = getDistanceFromLatLong(
          lat1,
          lon1,
          b?.geometry?.coordinates?.[1],
          b?.geometry?.coordinates?.[0]
        );
        return distanceA - distanceB;
      });

      setLocalEvent(sortedEvents[0] ?? null);
    }
  }, [currentLocation, data, isOpen]);

  const eventsData = useMemo(() => {
    return nearbyEventsQuery?.data?.length > 0 ||
      futureNearbyEventsQuery?.data?.length > 0
      ? [
          ...(nearbyEventsQuery?.data || []),
          ...(futureNearbyEventsQuery?.data || []),
        ].sort(() => Math.random() - 0.5)
      : [
          ...(londonEventsQuery?.data || []),
          ...(futureLondonEventsQuery?.data || []),
        ].sort(() => Math.random() - 0.5);
  }, [
    nearbyEventsQuery?.data,
    futureNearbyEventsQuery?.data,
    londonEventsQuery?.data,
    futureLondonEventsQuery?.data,
  ]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 12,
      }}
    >
      {nearbyEventsQuery?.isLoading ||
      londonEventsQuery?.isLoading ||
      futureLondonEventsQuery?.isLoading ||
      futureNearbyEventsQuery?.isLoading ? (
        <SkeletonElements />
      ) : (
        <>
          <SmallHeader headerText={headerText} distance={25} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              overflow: "scroll",
              marginRight: "-20px",
              paddingRight: 20,
              marginLeft: "-20px",
              paddingLeft: 20,
            }}
          >
            {eventsData?.map((event, index) => (
              <EventCard
                event={event}
                eventsData
                key={event.event_id}
                isEvent={true}
                vertical_position={0}
                horizontal_position={index}
                header={headerText}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default withCheckInContextProvider(NearYou);

NearYou.propTypes = {
  setLocalEvent: PropTypes.func,
};
