import { DEV_SEPOLIA_URL } from "../constants/constants";
import { isProd } from "../utils/utils";

let BLOCKCHAIN_URL = isProd
  ? import.meta.env.VITE_BLOCKCHAIN_API_URL
  : DEV_SEPOLIA_URL;

export const getEventTickets = async ({ eventId }) => {
  if (!eventId) {
    throw new Error("Missing required parameters");
  }
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/events/${eventId}/tickets`
    );

    if (!response.ok) {
      throw new Error("Failed to get event tickets");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getEventTicketsEstimate = async ({ eventId, artistId }) => {
  if (!eventId || !artistId) {
    throw new Error("Missing required parameters");
  }
  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/events/tickets/estimate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ eventId, artistId }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get event tickets estimate");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketABI = async () => {
  try {
    const response = await fetch(
      `https://cdn.momentify.xyz/ABI/Events/Tickets.sol/Tickets.json`
    );

    if (!response.ok) {
      throw new Error("Failed to get ticket ABI");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketManagerABI = async () => {
  const abiUrl = isProd
    ? "https://cdn.momentify.xyz/ABI/Events/TicketsManager.sol/TicketsManager.json"
    : "https://staging.mediacontent.momentify.xyz/ABI/Events/TicketsManager.sol/TicketsManager.json";

  try {
    const response = await fetch(abiUrl);

    if (!response.ok) {
      throw new Error("Failed to get ticket ABI");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getCollectiblesManagerABI = async () => {
  try {
    const response = await fetch(
      `https://cdn.momentify.xyz/ABI/Collectibles/CollectionManager.sol/CollectionManager.json`
    );

    if (!response.ok) {
      throw new Error("Failed to get ticket ABI");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTokensMinted = async ({ owner, contractAddress, txn }) => {
  if (!owner || !contractAddress || !txn) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(`${BLOCKCHAIN_URL}/onchain/mints`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ owner, contractAddress, txn }),
    });

    if (!response.ok) {
      throw new Error("Failed to get minted tickets");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const insertTicketsMinted = async ({
  tickets,
  userId,
  userWallet,
  txnHash,
  mints,
  referralCode,
}) => {
  if (!tickets || !userId || !userWallet || !txnHash || !mints) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/events/tickets/saveTicketMints`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tickets,
          userId,
          userWallet,
          txnHash,
          mints,
          referral_code: referralCode,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get event tickets estimate");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketsByUser = async ({ userId }) => {
  if (!userId) {
    throw new Error("Missing required parameters");
  }
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/tickets`
    );

    if (!response.ok) {
      throw new Error("Failed to get tickets by user");
    }

    const { data, success } = (await response.json()) || {};

    if (!success) {
      throw new Error("Failed to get tickets by user");
    }

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketDetails = async ({ userId, eventId, ticketType }) => {
  if (!userId) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/tickets/${eventId}/type/${ticketType}`
    );

    if (!response.ok) {
      throw new Error("Failed to get tickets by user");
    }

    const { data, success } = (await response.json()) || {};

    if (!success) {
      throw new Error("Failed to get tickets by user");
    }

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getWertKey = async () => {
  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/events/tickets/wert`
    );

    if (!response.ok) {
      throw new Error("Failed to get minted tickets");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getMintByTxn = async ({ txn }) => {
  if (!txn) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/events/tickets/checkMintedTicket/${txn}`
    );

    if (!response.ok) {
      throw new Error("Failed to get minted tickets");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const checkUserTickets = async ({ userId, eventId, artistId }) => {
  if (!userId || !eventId || !artistId) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/events/tickets/checkUserTickets`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, eventId, artistId }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get minted tickets");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deployMetadata = async ({ metadata }) => {
  if (!metadata) {
    throw new Error("Missing required parameters");
  }

  const {
    artistId,
    eventId,
    contractAddress,
    name,
    symbol,
    image,
    description,
    attributes,
  } = metadata || {};

  if (
    !artistId ||
    !eventId ||
    !contractAddress ||
    !name ||
    !symbol ||
    !description ||
    !attributes
  ) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/events/tickets/deployMetadata`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          artistId,
          eventId,
          contract_address: contractAddress,
          image,
          name,
          symbol,
          description,
          attributes,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to deploy metadata");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const refreshTicketKey = async ({ mintId, key }) => {
  if (!mintId || !key) {
    throw new Error("Missing required parameters");
  }
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/tickets/${mintId}/refresh/key`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
        }),
      }
    );

    if (!response.ok) {
      console.error("Failed to scan ticket");
      return null;
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const scanTicketKey = async ({ mintId }) => {
  if (!mintId) {
    throw new Error("Missing required parameters");
  }
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/tickets/${mintId}/scan`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          checkin_date: new Date().toISOString(),
        }),
      }
    );

    if (!response.ok) {
      console.error("Failed to scan ticket");
      return null;
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const logScannedTicketKey = async ({ mintId, status, fail_reason }) => {
  if (!mintId || !status) {
    throw new Error("Missing required parameters");
  }
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/tickets/${mintId}/log`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status,
          fail_reason,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to log ticket scan");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketByKey = async ({ mintId }) => {
  if (!mintId) {
    throw new Error("Missing required parameters");
  }
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/tickets/${mintId}/key`
    );

    if (!response.ok) {
      return false;
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getMintedTicket = async ({ userId, mintId }) => {
  if (!mintId || !userId) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/minted-tickets/${mintId}`
    );

    if (!response.ok) {
      return null;
    }

    const data = await response.json();

    if (!data?.success) {
      return null;
    }

    return data?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const transferTicketMinted = async ({ sender, receiver, ticket }) => {
  if (!sender || !receiver || !ticket) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/transfer-ticket`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sender, receiver, ticket }),
      }
    );

    if (!response.ok) {
      return null;
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getPromoterDetails = async ({ eventId, referralCode }) => {
  if (!eventId || !referralCode) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/tickets-promotion/${eventId}/referral/${referralCode}`
    );

    if (!response.ok) {
      throw new Error("Failed to get promoter details");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketBalance = async ({ walletAddress }) => {
  if (!walletAddress) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/escrow/mpc/balance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ walletAddress }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get ticket balance");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const generateTicketReferral = async ({ eventId, userId }) => {
  if (!eventId || !userId) {
    throw new Error("Missing required parameters");
  }
  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/events/tickets/insertReferral`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ eventId, userId }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to generate ticket referral");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const insertReferralVisit = async ({ userId, referralCode }) => {
  if (!userId || !referralCode) {
    throw new Error("Missing required parameters");
  }
  console.log({ BLOCKCHAIN_URL });
  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/events/tickets/insertReferralVisit`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, referralCode }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to insert referral visit");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketContract = async ({ eventId }) => {
  if (!eventId) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/tickets-promotion/contract/${eventId}`
    );

    if (!response.ok) {
      throw new Error("Failed to get ticket contract");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserTicketsToTransfer = async ({ userId }) => {
  if (!userId) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/tickets-to-transfer`
    );

    if (!response.ok) {
      throw new Error("Failed to get user tickets to transfer");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
