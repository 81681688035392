import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  padding: 24px;
  gap: 16px;
  z-index: 6;
`;

export const TicketDetails = styled.div``;
