import { useParams } from "react-router-dom";
import { useDigitalCollectibleQuery } from "./digitalCollectibleQueries";
import { Section } from "../../GlobalStyles";
import { NavBarTypes } from "../../constants/constants";
import TopNavV2 from "../../components/TopNavV2";
import { Container } from "../Collectible/Collectible-Styles";
import CollectibleAsset from "../../components/CollectiblePage/CollectibleAsset";
import CollectibleTitle from "../../components/CollectiblePage/CollectibleTitle";
import CollectibleArtist from "../../components/CollectiblePage/CollectibleArtist";
import CollectibleDescription from "../../components/CollectiblePage/CollectibleDescription";
import CollectibleUrls from "../../components/CollectiblePage/CollectibleUrls";

export default function DigitalCollectible() {
  const { collectibleId } = useParams();
  const { data, isLoading } = useDigitalCollectibleQuery({ collectibleId });
  const {
    collection_name,
    contract_address,
    image_url,
    chain,
    combined_artists,
    description,
    platform,
    platform_url,
  } = data || {};

  return (
    <Section>
      <TopNavV2 title={NavBarTypes.COLLECTIBLES} isBlurred isSticky />
      <Container style={{ padding: "0 20px 16px" }}>
        <CollectibleAsset
          image={image_url}
          chain={chain}
          isLoading={isLoading}
        />
        <CollectibleTitle
          edition_name={collection_name}
          isLoading={isLoading}
        />
        <CollectibleArtist artist={combined_artists} isLoading={isLoading} />
        <CollectibleDescription
          description={description}
          isLoading={isLoading}
        />
        <CollectibleUrls
          contractAddress={contract_address}
          isLoading={isLoading}
          platform={{
            platform,
            platform_url,
          }}
        />
      </Container>
    </Section>
  );
}
