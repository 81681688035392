import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.5)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 999;
  position: ${({ isMomentView }) => (isMomentView ? "fixed" : "sticky")};
  bottom: 0;
  width: 100%;
  padding: 12px 24px 15px;
`;
