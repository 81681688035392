import { sendMessage } from "../../../api/milo";
import { useMutation } from "@tanstack/react-query";
import Note from "../../../assets/svg/note.svg?react";
import Pin from "../../../assets/svg/pin-white.svg?react";
import Mic from "../../../assets/svg/mic.svg?react";
import TwoUsers from "../../../assets/svg/two-users.svg?react";
import { Heart } from "@phosphor-icons/react";

export const useSendMessage = () => {
  return useMutation({
    mutationKey: ["send-message"],
    mutationFn: async ({ message, id, address, country, userRealId }) => {
      const data = await sendMessage({
        message,
        id,
        address,
        country,
        userRealId,
      });

      return data;
    },
  });
};

export const useEventQuickChats = () => {
  return [
    {
      text: "My profile",
      icon: <Heart height={19} width={19} />,
      response: "Recommend events based on my profile.",
    },
    {
      text: "My location",
      icon: <Pin stroke="white" height={16} width={16} />,
      response: "Can you recommend me events near me?",
    },
    {
      text: "Friends' tastes",
      icon: <Heart height={19} width={19} />,
      response: "Suggest events based on friends’ tastes",
    },
    {
      text: "Genre I like",
      icon: <Note stroke="white" height={16} width={16} />,
      response: "Suggest events that fit the genres I like",
    },
    {
      text: "Artists I follow",
      icon: <Mic stroke="white" height={16} width={16} />,
      response: "Recommend events of artists I follow",
    },
    {
      text: "Friends' plans",
      icon: <TwoUsers stroke="white" height={16} width={16} />,
      response: "What are my friends' plans?",
    },
  ];
};
