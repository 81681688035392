import { Divider, Group, Stack, Text } from "@mantine/core";
import PropTypes from "prop-types";
import USDC from "../../assets/svg/usdc.svg?react";

export const PoolDetails = ({ poolPercentage, totalValue, totalReferrals }) => {
  const poolCommissions = isNaN(totalValue * (poolPercentage / 100))
    ? "0.00"
    : (totalValue * (poolPercentage / 100)).toFixed(2);

  // Safely calculate per-referral commission
  const perReferralCommission =
    totalReferrals > 0 ? (poolCommissions / totalReferrals).toFixed(2) : "0.00";

  return (
    <Group
      style={{
        backgroundColor: "#25254380",
        borderRadius: 8,
        padding: "16px 27.5px",
      }}
      gap={24}
      align="center"
      justify="center"
    >
      <Stack gap={12}>
        <Text
          style={{
            fontFamily: "Suisse Intl",
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "100%",
            textAlign: "center",
            textDecorationSkipInk: "none",
            color: "white",
          }}
        >
          Promotion pool size
        </Text>
        <Group justify="center" gap={8}>
          <Text
            style={{
              fontFamily: "Suisse Intl",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "100%",
              color: "#99999D",
            }}
          >
            {poolPercentage ?? 0}%
          </Text>
          <Group gap={4}>
            <Text
              style={{
                fontFamily: "Suisse Intl",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "100%",
                color: "#F7F7F7",
              }}
            >
              ${poolCommissions ?? 0}
            </Text>
            <USDC
              width={12}
              height={12}
              style={{ bottom: 0.5, position: "relative" }}
            />
          </Group>
        </Group>
      </Stack>
      <Divider orientation="vertical" color="#FFFFFF26" h={42} />
      <Stack gap={12}>
        <Text
          style={{
            fontFamily: "Suisse Intl",
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "100%",
            textAlign: "center",
            textDecorationSkipInk: "none",
            color: "white",
          }}
        >
          Total referral purchases
        </Text>
        <Group justify="center" gap={8}>
          <Text
            style={{
              fontFamily: "Suisse Intl",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "100%",
              color: "#F7F7F7",
            }}
          >
            {totalReferrals ?? 0}
          </Text>
          <Text
            style={{
              fontFamily: "Suisse Intl",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "100%",
              color: "#99999D",
            }}
          >
            = ${perReferralCommission} per referral
          </Text>
        </Group>
      </Stack>
    </Group>
  );
};

PoolDetails.propTypes = {
  poolPercentage: PropTypes.number.isRequired,
  totalValue: PropTypes.number.isRequired,
  totalReferrals: PropTypes.number.isRequired,
};
