import { TICKET_SOURCES } from "../constants/constants";

const {
  SKIDDLE,
  GIGANTIC,
  WEGOTTICKETS,
  TICKETMASTER,
  SEETICKETS,
  LIVENATION,
  TICKETCITY,
  DICE,
  STUBHUB,
} = TICKET_SOURCES;

export const addSkiddleAffiliate = (url) => {
  const trimmedUrl = url.split("?")[0];

  const newUrl = `${trimmedUrl}${SKIDDLE.SUFFIX}`;

  return newUrl;
};

export const addGiganticAffiliate = (url) => {
  const trimmedUrl = url.split("?")[0];

  const newUrl = `${trimmedUrl}${GIGANTIC.SUFFIX}`;

  return newUrl;
};

export const addWeGotTicketsAffiliate = (url) => {
  const regex = /\/af\/\d+\//;

  return url.replace(regex, WEGOTTICKETS.SUFFIX);
};

export const generateTicketAffiliate = ({ url, type }) => {
  if (!type) return url;

  switch (type?.toLowerCase()) {
    case SKIDDLE.NAME.toLowerCase():
      return addSkiddleAffiliate(url);
    case GIGANTIC.NAME.toLowerCase():
      return addGiganticAffiliate(url);
    case WEGOTTICKETS.NAME.toLowerCase():
      return addWeGotTicketsAffiliate(url);
    default:
      return url;
  }
};

export const ticketImage = (type) => {
  switch (type?.toLowerCase()) {
    case SKIDDLE.NAME.toLowerCase():
      return SKIDDLE.IMAGE;
    case GIGANTIC.NAME.toLowerCase():
      return GIGANTIC.IMAGE;
    case WEGOTTICKETS.NAME.toLowerCase():
      return WEGOTTICKETS.IMAGE;
    case TICKETMASTER.NAME.toLowerCase():
      return TICKETMASTER.IMAGE;
    case SEETICKETS.NAME.toLowerCase():
      return SEETICKETS.IMAGE;
    case LIVENATION.NAME.toLowerCase():
      return LIVENATION.IMAGE;
    case TICKETCITY.NAME.toLowerCase():
      return TICKETCITY.IMAGE;
    case DICE.NAME.toLowerCase():
      return DICE.IMAGE;
    case STUBHUB.NAME.toLowerCase():
      return STUBHUB.IMAGE;
    default:
      return "/images/milo/default.svg";
  }
};
