import styled from "styled-components";

export const TicketContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 10px;
  padding: 16px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const TicketHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
`;

export const TicketDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TicketFees = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
`;

export const TicketButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
