import useGlobalModal from "../../hooks/useGlobalModal";
import PropTypes from "prop-types";
import Sheet from "react-modal-sheet";
import { theme } from "../../utils/theme";
import useMomentPhotoUpload from "../../hooks/useMomentPhotoUpload";
import { useCheckoutStore } from "../Checkout/checkoutStore";
import { useBuyTicketStore } from "../BuyTicket/buyTicketStore";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { PAYMENT_METHODS } from "../../constants/constants";
import { useTicketTransferStore } from "../../routes/TicketTransfer/stores/ticketTransferStore";

const { CREDIT_CARD } = PAYMENT_METHODS;

const defaultStyle = {
  sheet: {
    zIndex: 99999,
  },
  container: {
    background: theme.colors.background,
    borderRadius: "20px 20px 0 0",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: "12px",
  },
};

export default function SheetModal() {
  const { loggedInUser } = useAuth();
  const {
    isSheetModalOpen,
    sheetModalContent,
    sheetBackdropStyle,
    setSheetModalContent,
    setSheetModalBackdropStyle,
    closeSheetModal,
  } = useGlobalModal();
  const { loading } = useMomentPhotoUpload();
  const {
    setPaymentMethod,
    setCardPaymentMethod,
    boughtTickets,
    reset: resetCheckout,
    isMinting,
    setError,
    paymentMethod,
  } = useCheckoutStore();
  const { reset: resetBuyTicket, isModal } = useBuyTicketStore();
  const { setIsModal } = useTicketTransferStore();
  const navigate = useNavigate();

  const closeModal = () => {
    if (loading || (isMinting && paymentMethod !== CREDIT_CARD)) return;

    setSheetModalBackdropStyle({});

    if (boughtTickets?.length > 0 && !isModal) {
      navigate(`/profile/${loggedInUser?.id}`);
      closeSheetModal();
      setSheetModalContent(null);

      resetBuyTicket();
      resetCheckout();
      return;
    }

    setIsModal(false);
    setError(null);
    setSheetModalContent(null);
    setPaymentMethod(null);
    setCardPaymentMethod(null);
    closeSheetModal();
  };

  const mergedStyle = {
    sheet: {
      ...defaultStyle.sheet,
    },
    container: {
      ...defaultStyle.container,
    },
    content: {
      ...defaultStyle.content,
    },
    backdrop: {
      ...defaultStyle.backdrop,
      ...sheetBackdropStyle,
    },
  };

  return (
    <Sheet
      isOpen={isSheetModalOpen}
      onClose={closeModal}
      detent="content-height"
      style={mergedStyle.sheet}
    >
      <Sheet.Container style={mergedStyle.container}>
        {(!isMinting || paymentMethod === CREDIT_CARD) && <Sheet.Header />}
        <Sheet.Content>
          <Sheet.Scroller draggableAt="both" style={mergedStyle.content}>
            {sheetModalContent}
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={closeModal} style={mergedStyle.backdrop} />
    </Sheet>
  );
}

SheetModal.propTypes = {
  modalProps: PropTypes.object,
};
