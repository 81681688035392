import { useNavigate, useParams } from "react-router-dom";
import { useGetTickets } from "./ticketQueries";
import { Section } from "../../GlobalStyles";
import TopNav from "../../components/TopNav";
import { Container } from "../Collectible/Collectible-Styles";
import { NavBarTypes } from "../../constants/constants";
import ErrorPage from "../Error";
import TicketHeader from "../../components/TicketPage/TicketHeader";
import TicketImage from "../../components/TicketPage/TicketImage";
import TicketEventDetails from "../../components/TicketPage/TicketEvent";
import TicketOwner from "../../components/TicketPage/TicketOwner";
import TicketTokens from "../../components/TicketPage/TicketTokens";
import TicketUrls from "../../components/TicketPage/TicketUrls";
import TicketAdditionalInfo from "../../components/TicketPage/TicketAdditionalInfo";
import { useTicketTransferStore } from "../TicketTransfer/stores/ticketTransferStore";
import { useEffect } from "react";

export default function Ticket() {
  const { profileId, eventId, ticketType } = useParams();
  const navigate = useNavigate();
  const { reset } = useTicketTransferStore();
  const { data, isLoading } = useGetTickets({
    userId: profileId,
    eventId,
    type: ticketType,
  });

  const {
    type,
    price,
    image,
    contract_address,
    contract_data,
    resale_max_setting,
  } = data?.[0] || {};

  useEffect(() => {
    reset();
  }, [reset]);

  const handleBack = () => {
    navigate(`/profile/${profileId}`);
  };

  if (!isLoading && (data?.length === 0 || !data)) {
    return <ErrorPage description={"Can't seem to find this ticket."} />;
  }

  return (
    <Section>
      <TopNav type={NavBarTypes.TICKET} onBack={handleBack} />
      <Container style={{ padding: "0 20px 16px" }}>
        <TicketHeader
          type={type}
          price={price}
          count={data?.length}
          isLoading={isLoading}
        />
        <TicketImage image={image} isLoading={isLoading} />
        <TicketEventDetails ticket={data?.[0]} isLoading={isLoading} />
        <TicketOwner ticket={data?.[0]} isLoading={isLoading} />
        <TicketTokens tickets={data} isLoading={isLoading} />
        <TicketUrls contractAddress={contract_address} isLoading={isLoading} />
        <TicketAdditionalInfo
          contractData={{
            ...contract_data,
            ticketType,
            contract_address,
            resale_max_setting,
            price,
          }}
          isLoading={isLoading}
        />
      </Container>
    </Section>
  );
}
