import { Avatar } from "@mantine/core";
import { ButtonRectSm } from "../../../GlobalStyles";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";

export default function MiloButton({ text, suggestion }) {
  const { loggedInUser, login } = useMomentifyLogin();
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (!loggedInUser) return login();

    if (!suggestion) return navigate("/milo-chat");

    navigate(`/milo-chat?suggestion=${suggestion}`);
  };
  return (
    <ButtonRectSm
      style={{
        background: "linear-gradient(270deg, #104E4E 0%, #2D2D52 83%)",
        backgroundColor: "#2D2D52",
        borderRadius: "10px",
        gap: 8,
        padding: "8px 12px",
      }}
      onClick={handleOnClick}
    >
      <Avatar src="/images/milo/milo.gif" size="20px" />
      {text}
    </ButtonRectSm>
  );
}

MiloButton.propTypes = {
  text: PropTypes.string,
  suggestion: PropTypes.string,
};
