import { useEffect, useRef } from "react";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { useVideoEditorStore } from "./videoEditorStore";
import {
  ButtonsContainer,
  Container,
  EditorContainer,
  Video,
  VideoContainer,
} from "./VideoEditor-Styles";
import { NavContainer } from "../TopNav";
import {
  BodyText,
  ButtonRect,
  FooterText,
  InvisibleInputImage,
  Title,
} from "../../GlobalStyles";
import useMomentPhotoUpload from "../../hooks/useMomentPhotoUpload";
import { generateThumbnails } from "../../utils/canvas-utils";
import { formatTime } from "../../utils/utils";
import VideoTrimmer from "./VideoTrimmer";
import {
  BACKGROUND_IMAGES,
  MAX_VIDEO_DURATION,
} from "../../constants/constants";
import { theme } from "../../utils/theme";
import { BetaIcon } from "../BetaIcon";
import BackButton from "../BackButton";
import useGlobalModal from "../../hooks/useGlobalModal";
import UploadMediaModal from "../Modal/UploadMediaModal";
import { RetroMomentPhoto } from "../Moments/MomentPhotos/MomentPhotos-Styles";

const { colors } = theme;

export default function VideoEditor() {
  const ffmpegRef = useRef(new FFmpeg());
  const {
    video,
    outputVideo,
    setOutputVideo,
    setVideoToUpload,
    videoToUpload,
    startTime,
    endTime,
    videoDuration,
    setVideoDuration,
    outputVideoDuration,
    setOutputVideoDuration,
    setMarks,
    setThumbnails,
    setIsLoading,
    isLoading,
    reset,
    isVideoProcessing,
    setIsVideoProcessing,
    isVideoUploading,
    isNotAllowed,
    setIsNotAllowed,
  } = useVideoEditorStore();
  const { handleMediaChange } = useMomentPhotoUpload();
  const { toggleSheetModal, setSheetModalContent } = useGlobalModal();
  const origVidRef = useRef(null);
  const outputVideoRef = useRef(null);
  const loading = isLoading || isVideoProcessing || isVideoUploading;

  useEffect(() => {
    if (!video) return;

    setIsLoading(true);

    const videoElement = origVidRef.current;
    videoElement.onloadedmetadata = async () => {
      const duration = videoElement.duration;
      setVideoDuration(duration);

      const marks = [
        formatTime(0),
        formatTime(duration / 4),
        formatTime(duration / 2),
        formatTime((3 * duration) / 4),
        formatTime(duration),
      ];

      setMarks(marks);
      // Generate thumbnails based on the marks
      generateThumbnails(videoElement, setThumbnails, duration);

      if (duration < MAX_VIDEO_DURATION) await trimVideo();
      else setIsNotAllowed(true);
    };

    setIsLoading(false);
  }, [video]);

  useEffect(() => {
    if (!outputVideo) return;

    const videoElement = outputVideoRef.current;
    videoElement.onloadedmetadata = async () => {
      const duration = videoElement.duration;
      setOutputVideoDuration(duration);
    };
  }, [outputVideo]);

  const loadFFmpeg = async () => {
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.7/dist/umd";

    const ffmpeg = ffmpegRef.current;

    ffmpeg.on("error", console.warn);

    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm"
      ),
    });
  };

  const trimVideo = async () => {
    if (
      !video ||
      startTime < 0 ||
      !endTime ||
      isNotAllowed ||
      isVideoProcessing ||
      isVideoUploading
    )
      return;

    if (endTime - startTime > MAX_VIDEO_DURATION || endTime - startTime < 0)
      return console.warn(
        "Video duration is too long or start time is greater than end time"
      );

    if (endTime < startTime || startTime > endTime)
      return console.warn("Start time and end times are invalid");

    setIsVideoProcessing(true);
    setIsLoading(true);
    try {
      const ffmpeg = ffmpegRef.current;

      if (!ffmpeg.loaded) await loadFFmpeg();

      // Load the video file into FFmpeg
      await ffmpeg.writeFile("input.mp4", await fetchFile(video));

      // Run the trimming command
      await ffmpeg.exec([
        "-ss",
        `${startTime}`,
        "-to",
        `${endTime}`,
        "-i",
        "input.mp4",
        "-c",
        "copy",
        "output.mp4",
      ]);

      // Get the output video from FFmpeg
      const trimmedVideo = await ffmpeg.readFile("output.mp4");

      const url = URL.createObjectURL(
        new Blob([trimmedVideo.buffer], { type: "video/mp4" })
      );

      const processedBlob = new Blob([trimmedVideo.buffer], {
        type: "video/mp4",
      });

      setOutputVideo(url);
      setVideoToUpload(processedBlob);
      setIsVideoProcessing(false);
      setIsLoading(false);
    } catch (err) {
      console.error("Error trimming video", err);
      setIsVideoProcessing(false);
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    reset();
  };

  const handleSubmit = () => {
    toggleSheetModal();
    setSheetModalContent(<UploadMediaModal media={outputVideo} type="video" />);
  };

  return (
    <Container>
      <NavContainer
        style={{ position: "static", justifyContent: "flex-start" }}
      >
        <BackButton onClick={handleBack} />
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Title weight="400">Video Editor</Title>
          <BetaIcon />
        </div>
      </NavContainer>
      {video && (
        <video
          src={video}
          ref={origVidRef}
          style={{ width: "100%", objectFit: "cover", display: "none" }}
          muted
        />
      )}
      {(outputVideo || video) && (
        <EditorContainer>
          <VideoContainer>
            <RetroMomentPhoto
              src={BACKGROUND_IMAGES.SEARCH.WEEK}
              style={{ zIndex: -1 }}
            />
            <Video
              src={outputVideo || video}
              ref={outputVideoRef}
              controls
              autoPlay
              loop
              playsInline
              muted
            />
            <BodyText
              style={{
                position: "absolute",
                bottom: "16px",
                right: "16px",
              }}
            >
              {isVideoProcessing
                ? "Processing..."
                : outputVideoDuration
                  ? formatTime(outputVideoDuration)
                  : videoDuration
                    ? formatTime(videoDuration)
                    : ""}
            </BodyText>
          </VideoContainer>
          <FooterText style={{ textAlign: "center" }}>
            You can only upload videos up to 1 minute.
          </FooterText>
          {videoDuration !== 0 && <VideoTrimmer trimVideo={trimVideo} />}
          <ButtonsContainer>
            <ButtonRect
              style={{
                width: "fit-content",
              }}
              disabled={loading}
            >
              <BodyText weight="600">Change video</BodyText>
              <InvisibleInputImage
                type="file"
                accept="video/*"
                onChange={(e) => handleMediaChange(e, "video")}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
            </ButtonRect>
            <ButtonRect
              onClick={handleSubmit}
              style={{
                width: "fit-content",
                backgroundColor: colors.green,
              }}
              disabled={
                loading ||
                isNotAllowed ||
                !video ||
                !outputVideo ||
                !videoToUpload
              }
            >
              <BodyText weight="600" color={colors.black}>
                Submit
              </BodyText>
            </ButtonRect>
          </ButtonsContainer>
        </EditorContainer>
      )}
    </Container>
  );
}
