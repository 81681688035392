import PropTypes from "prop-types";
import PosterIcon from "../../../assets/svg/poster-icon.svg?react";
import PhotoIcon from "../../../assets/svg/photo.svg?react";
import FlyerIcon from "../../../assets/svg/flyer.svg?react";
import ExclusiveIcon from "../../../assets/svg/exclusive.svg?react";
import TicketStubIcon from "../../../assets/svg/ticket-stub.svg?react";
import StickerIcon from "../../../assets/svg/sticker-circle.svg?react";
import MessagePlay from "../../../assets/svg/message-play.svg?react";
import Music from "../../../assets/svg/music.svg?react";
import { theme } from "../../../utils/theme";

const { colors } = theme;

export default function CollectibleIcon({ type, isThumbnail }) {
  const size = isThumbnail ? 14 : 17;

  if (type === "Poster") {
    return <PosterIcon stroke={colors.grey} height={size} width={size} />;
  }
  if (type === "Photo") {
    return <PhotoIcon stroke={colors.grey} height={size} width={size} />;
  }
  if (type === "Flyer") {
    return <FlyerIcon stroke={colors.grey} height={size} width={size} />;
  }
  if (type === "Exclusive") {
    return <ExclusiveIcon stroke={colors.grey} height={size} width={size} />;
  }
  if (type === "Ticket stub") {
    return <TicketStubIcon stroke={colors.grey} height={size} width={size} />;
  }
  if (type === "Sticker") {
    return <StickerIcon stroke={colors.grey} height={size} width={size} />;
  }
  if (type === "Message") {
    return <MessagePlay stroke={colors.grey} height={size} width={size} />;
  }
  if (type === "Track") {
    return <Music stroke={colors.grey} height={size} width={size} />;
  }

  return null;
}

CollectibleIcon.propTypes = {
  type: PropTypes.string,
  isThumbnail: PropTypes.bool,
};
