import PropTypes from "prop-types";
import { GlassmorphPlaceholder } from "../../../constants/constants";
import { FooterText, OneLineText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import {
  EventDetailsContainer,
  EventImage,
  EventTextContainer,
} from "../../CheckIn/CheckInToast/CheckInToast-Styles";
import _moment from "moment";
import { useBuyTicketStore } from "../buyTicketStore";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

const { colors } = theme;

export default function EventDetails({ event, isLoading }) {
  const { contract } = useBuyTicketStore();
  const { headline_artist, event_name, venue, country, date } = event || {};
  const { image } = contract || {};

  if (isLoading) {
    return (
      <EventDetailsContainer
        style={{
          justifyContent: "flex-start",
          padding: 0,
        }}
      >
        <SkeletonElement height="58px" width="58px" radius="10px" />
        <EventTextContainer
          style={{
            alignItems: "flex-start",
            justifyContent: "center",
            gap: 4,
          }}
        >
          <SkeletonElement
            height="14px"
            width="100px"
            style={{ marginBottom: "4px" }}
          />
          <SkeletonElement height="14px" width="120px" />
          <SkeletonElement height="14px" width="150px" />
        </EventTextContainer>
      </EventDetailsContainer>
    );
  }

  return (
    <EventDetailsContainer
      style={{
        justifyContent: "flex-start",
        padding: 0,
      }}
    >
      <EventImage
        src={image ?? GlassmorphPlaceholder}
        alt={event}
        onError={(e) => (e.target.src = GlassmorphPlaceholder)}
      />

      <EventTextContainer
        style={{
          alignItems: "flex-start",
        }}
      >
        <OneLineText weight="600" style={{ marginBottom: "4px" }}>
          {headline_artist || event_name}
        </OneLineText>
        <OneLineText color={colors.grey} size={theme.fontSizes.mobile.footer}>
          {venue}, {country}
        </OneLineText>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <FooterText color={colors.green}>
            {_moment(date).format("ddd DD MMM YYYY")}
          </FooterText>
          <FooterText color={colors.grey}>
            {_moment(date).format("LT")}
          </FooterText>
        </div>
      </EventTextContainer>
    </EventDetailsContainer>
  );
}

EventDetails.propTypes = {
  event: PropTypes.object,
  isLoading: PropTypes.bool,
};
