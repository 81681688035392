import ProfilePlaceholder from "../assets/svg/avatar.svg";
import _EventPlaceholder from "../assets/svg/event-placeholder.svg";
import _ArtistPlaceholder from "../assets/svg/artist-placeholder.svg";
import Glassmorph from "../assets/svg/glassmorph.svg";
import _VenuePlaceholder from "../assets/svg/venue-placeholder.svg";

export const StockImage = "https://i.imgur.com/uQcFked.png";

export const MomentStockImage = "https://i.imgur.com/7ZO53Pg.png";

export const MomentifyLogo =
  "https://mediacontent.momentify.xyz/media/app-logo.png";

export const MomentifyLogo2 =
  "https://mediacontent.momentify.xyz/media/Cube_CL_300.png";

export const WebsiteLink = "http://www.momentify.xyz";

export const AvatarPlaceholder = ProfilePlaceholder;

export const EventPlaceholder = _EventPlaceholder;

export const ArtistPlaceholder = _ArtistPlaceholder;

export const GlassmorphPlaceholder = Glassmorph;

export const VenuePlaceholder = _VenuePlaceholder;

export const NearbyMeters = 500;

export const UsernameTypes = {
  USERNAME: "username",
  EMAIL: "email",
};

export const NavBarTypes = {
  PROFILE: "Profile",
  EDIT_PROFILE: "Edit Profile",
  CREATE_PROFILE: "Create Profile",
  SETTINGS: "Settings",
  BADGES: "Badges",
  FOLLOWING: "Following",
  FOLLOWERS: "Followers",
  NOTIFICATIONS: "Notifications",
  MESSAGES: "Messages",
  RETRO: "Add past Events",
  WALLET: "Wallet",
  REPORT: "Report",
  COLLECTIBLES: "Collectibles",
  XP_HISTORY: "XP History",
  TICKET: "Ticket",
};

export const TopNavBarHeight = "64px";
export const BottomNavBarHeight = "64px";

export const wembleyLocation = {
  coords: {
    latitude: 51.5560247,
    longitude: -0.2796177,
  },
};

export const EventItemTypes = {
  PROFILE: "profile",
  SEARCH: "search",
  FESTIVAL: "festival",
  VENUE: "venue",
};

export const FestivalEventType = {
  TODAY: "today",
  UPCOMING: "upcoming",
};

export const BADGE_TYPES = [
  {
    type: "FirstFestivalCheckin",
    festival: null,
    name: "First Festival",
    description: "First checkin to any festival event",
  },
  {
    type: "AAA-LevelOne",
    festival: "AAA",
    name: "Level 1",
    description: "Check in to one (1) AAA event",
  },
  {
    type: "AAA-LevelTwo",
    festival: "AAA",
    name: "Level 3",
    description: "Check in to three (3) AAA events",
  },
  {
    type: "AAA-LevelThree",
    festival: "AAA",
    name: "Level 3",
    description: "Check in to six (6) AAA events",
  },
];

export const PROFILE_BADGE_TYPES = {
  ATTAINED: "attained",
  NEXT: "next",
};

export const MOMENT_CLAIM_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

export const MINT_ERROR_MESSAGES = {
  NO_PRIMARY_WALLET: {
    DETAILS: "Primary wallet not set.",
    MESSAGE: "Primary wallet not set.",
  },
  PRIMARY_WALLET_NOT_CONNECTED: {
    DETAILS: "Primary wallet not connected.",
    MESSAGE: "Primary wallet not connected.",
  },
  PRIO_ZERO: {
    DETAILS:
      "precheck failed: maxPriorityFeePerGas is 0 but must be at least 100000",
    MESSAGE: "Collection currently switched off.",
  },
  PENDING: {
    DETAILS: "Moment is still pending.",
    MESSAGE: "This transaction is taking a while. Please check later.",
  },
  TIMEOUT: {
    DETAILS: "Transaction timed out.",
    MESSAGE: "Transaction timed out. Please try again",
  },
  HIGH_GAS: {
    DETAILS: "User operation cost exceeds specified spend limit",
    MESSAGE: "Transaction fees currently too high. Please try again later.",
  },
  ALREADY_CLAIMED: {
    DETAILS: "You already claimed this Moment.",
    MESSAGE: "You already claimed this Moment.",
  },
  FAILED_UPLOAD: {
    DETAILS: "Failed to upload Moment.",
    MESSAGE: "Something went wrong. Please try again.",
  },
};

export const BUTTON_TYPES = {
  SQUARE: "square",
  RECTANGLE: "rectangle",
};

export const BUTTON_SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  WIDE: "wide",
};

export const SEARCH_MODES = {
  HOME_MODE: "home_mode",
  RECENTS_MODE: "recents_mode",
  INPUT_MODE: "input_mode",
  SUBMIT_MODE: "submit_mode",
};

export const CDN_LINKS = {
  APP: "https://mediacontent.momentify.xyz",
  STAGING: "https://staging.mediacontent.momentify.xyz",
  SD: "https://diffuse.momentify.xyz",
};

export const DESKTOP_IMAGES = {
  GRAINY_BG: "https://mediacontent.momentify.xyz/website/hero-bg.webp",
  BLACK_POSTER: "https://mediacontent.momentify.xyz/website/hero-poster.webp",
  QR: "https://mediacontent.momentify.xyz/website/qr-app.webp",
};

export const DESKTOP_VIDEOS = {
  INTERIM: "https://mediacontent.momentify.xyz/website/new-interim.webm",
};

export const BACKGROUND_IMAGES = {
  SEARCH: {
    CITY: "https://mediacontent.momentify.xyz/media/backgrounds/a43p8-digru.webp",
    HOME: "https://mediacontent.momentify.xyz/media/backgrounds/ayxmj-8lfk4.webp",
    WEEK: "https://mediacontent.momentify.xyz/media/backgrounds/a7eqj-tivq2.webp",
    MONTH:
      "https://mediacontent.momentify.xyz/media/backgrounds/aemhm-8qnj4.webp",
  },
  LOGIN: "https://mediacontent.momentify.xyz/website/hero-bg-new.webp",
  ERROR:
    "https://mediacontent.momentify.xyz/media/backgrounds/a8zpk-k4wov.webp",
};

export const MOMENT_TYPES = {
  UNLOCKED: "unlocked",
  ONCHAIN: "onchain",
  RETRO: "retro",
};

export const EPHEMERAL_TIPS = {
  HOME_TIP_EVENTS: "HOME_TIP_EVENTS",
  HOME_TIP_PROFILE: "HOME_TIP_PROFILE",
  HOME_TIP_SEARCH: "HOME_TIP_SEARCH",
  MOMENT_TIP: "MOMENT_TIP",
};

export const TICKET_SOURCES = {
  TICKETMASTER: {
    NAME: "Ticketmaster",
    SUFFIX: "",
    IMAGE: "/images/milo/ticketmaster.svg",
  },
  SEETICKETS: {
    NAME: "SeeTickets",
    SUFFIX: "",
    IMAGE: "/images/milo/seetickets.svg",
  },
  WEGOTTICKETS: {
    NAME: "WeGotTickets",
    SUFFIX: "/af/646/",
    IMAGE: "/images/milo/wegottickets.svg",
  },
  SKIDDLE: {
    NAME: "Skiddle",
    SUFFIX: "?sktag=15325",
    IMAGE: "/images/milo/skiddle.svg",
  },
  GIGANTIC: {
    NAME: "Gigantic",
    SUFFIX:
      "?affiliate=momentify&utm_source=momentify&utm_medium=affiliate&utm_campaign=momentify",
    IMAGE: "/images/milo/gigantic.svg",
  },
  LIVENATION: {
    NAME: "Livenation",
    SUFFIX: "",
    IMAGE: "/images/milo/livenation.svg",
  },
  TICKETCITY: {
    NAME: "TicketCity",
    SUFFIX: "",
    IMAGE: "/images/milo/ticketcity.svg",
  },
  DICE: {
    NAME: "Dice",
    SUFFIX: "",
    IMAGE: "/images/milo/dice.svg",
  },
  STUBHUB: {
    NAME: "StubHub",
    SUFFIX: "",
    IMAGE: "/images/milo/stubhub.svg",
  },
};

export const REPORT_TYPES = {
  EVENT: "Event Issue",
  ARTIST: "Artist Issue",
  VENUE: "Venue Issue",
};

export const REPORT_ENTITY_TYPES = {
  EVENT_ENTITY: "Event",
  ARTIST_ENTITY: "Artist",
  VENUE_ENTITY: "Venue",
};

export const REPORT_REASONS = {
  MISSING_EVENT: "Missing Event",
  WRONG_VENUE: "Wrong Venue",
  DATE_OR_TIME: "Date or Time",
  MAIN_ARTIST: "Main Artist",
  SUPPORTING_ARTIST: "Supporting Artist",
  IMAGE: "Image",
};

export const XP_ACTION_TYPES = {
  LIKE: "like",
  PIN: "pin",
  SUBSCRIBE_ARTIST: "subscribe_artist",
  FOLLOW_ARTIST: "follow_artist",
  FOLLOW_USER: "follow_user",
  INVITE_FRIEND: "invite_friend",
  EVENT_CHECKIN: "event_checkin",
  ADD_MOMENT_PHOTO: "add_moment_photo",
  COLLECT_MOMENT: "collect_moment",
  LINK_EMAIL: "link_email",
  ADD_LOCATION: "add_location",
  ADD_FAV_ARTIST: "add_fav_artist",
  ADD_MOMENT_PHOTO_SUBTRACT: "add_moment_photo_subtract",
  DATA_REPORT: "data_report",
};

export const MAX_VIDEO_DURATION = 60;

export const PAYMENT_METHODS = {
  WALLET: "Wallet",
  CREDIT_CARD: "Credit card",
  NEW_CARD: "Add a new card",
};

export const USDC_ADDRESS = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913";
export const USDC_ADDRESS_SEPOLIA =
  "0x036CbD53842c5426634e7929541eC2318f3dCF7e";

export const DEV_SEPOLIA_URL = "https://dev.sepolia.blockchain.momentify.xyz";
export const STAGING_SEPOLIA = "https://dev.blockchain.momentify.xyz";

export const TICKETS_MANAGER_ADDRESS =
  "0x3660220f72e8EF4c5dcb09ff14FAE776E5A708a6";
export const SAMPLE_TICKETS_CONTRACT_ADDRESS =
  "0xEB762a60bc6422143F956b46D8E92A9627678B8c";
export const WERT_TEST_TOKEN = "0x3bE123Ff0ec7c0717D6C05C8957EA7880e2FfDcb";

export const LOGIN_LINKS = {
  WHATSAPP: "https://chat.whatsapp.com/DeJzrqYGnqjL8LsWwVyz5G",
  TELEGRAM: "https://t.me/+biWclgxp3FtlMmE0",
};

export const USER_TICKETS_FILTERS = {
  ALL: "all",
  UPCOMING: "upcoming",
  PAST: "past",
};

export const APPLICATION_ICONS = [
  {
    name: "sound",
    icon: "https://mediacontent.momentify.xyz/artists/icons/sound.jpg",
  },
  {
    name: "supercollector",
    icon: "https://mediacontent.momentify.xyz/artists/icons/supercollector.jpg",
  },
  {
    name: "pods",
    icon: "https://mediacontent.momentify.xyz/artists/icons/pods.png",
  },
];
