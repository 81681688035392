import PropTypes from "prop-types";
import {
  Container,
  Content,
  ContentContainer,
  DescriptionContainer,
  TicketContainer,
} from "./Ticket-Styles";
import { useEffect, useMemo, useState } from "react";
import { useBuyTicketStore } from "../buyTicketStore";
import { useCheckoutStore } from "../../Checkout/checkoutStore";
import TicketHeader from "./TicketHeader";
import TicketButtons from "./TicketButtons";
import TicketDetails from "./TicketDetails";
import { FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import ExpandableText from "../../ExpandableText";

export default function Ticket({ ticket, status, isLoading }) {
  const { type, price, quantity: ticketQuantity, description } = ticket || {};
  const { userTickets } = status || {};
  const { ticketsSelected, updateTicketSelected, contract } =
    useBuyTicketStore();
  const { momentifyFee, feesEnabled, isPercentage } = contract || {};
  const { handleTotal, handleTicketsTotal, handleMomentifyFee } =
    useCheckoutStore();
  const [quantity, setQuantity] = useState(0);

  const fee = useMemo(() => {
    if (feesEnabled && isPercentage && price > 0) {
      const percentageFee = momentifyFee / 100;
      return Number((price * percentageFee).toFixed(3));
    }

    if (feesEnabled && price > 0) {
      return Number(momentifyFee);
    }

    if (!feesEnabled || price === 0) return 0;

    return 0;
  }, [feesEnabled, price, momentifyFee, isPercentage]);

  const remainingTickets = useMemo(() => {
    return userTickets?.find((ticket) => ticket.type === type)?.remaining;
  }, [userTickets, type]);

  useEffect(() => {
    ticketsSelected?.length === 0 && setQuantity(0);
  }, [ticketsSelected]);

  const handleQuantityChange = (newQuantity, isRemove) => {
    if (newQuantity > ticketQuantity || newQuantity < 0) return;

    const convertedFee = price > 0 ? Number(fee) : 0;
    const priceWithFee = price + convertedFee;

    setQuantity(newQuantity);
    updateTicketSelected(ticket, isRemove);
    handleTicketsTotal(isRemove ? -price : price);
    handleMomentifyFee(isRemove ? -convertedFee : convertedFee);
    handleTotal(isRemove ? -priceWithFee : priceWithFee);
  };

  return (
    <TicketContainer>
      <Container>
        <ContentContainer>
          <TicketHeader
            ticket={{ type, quantity, remaining: remainingTickets }}
          />
          <Content>
            <TicketDetails price={price} fee={fee} />
            {remainingTickets > 0 && (
              <TicketButtons
                ticket={ticket}
                quantitySelected={quantity}
                remaining={remainingTickets}
                handleQuantityChange={handleQuantityChange}
                status={status}
                isLoading={isLoading}
              />
            )}
          </Content>
        </ContentContainer>
        {remainingTickets === 0 && (
          <FooterText
            style={{
              color: theme.colors.red,
              whiteSpace: "nowrap",
            }}
          >
            Sold out!
          </FooterText>
        )}
      </Container>
      {description && (
        <DescriptionContainer>
          <ExpandableText
            text={
              description ??
              `Bacon ipsum dolor amet corned beef capicola shankle burgdoggen short ribs pork belly spare ribs ball tip sausage biltong pork belly spare ribs ball ti`
            }
            maxLength={0}
            isSecondary
            isDescription
          />
        </DescriptionContainer>
      )}
    </TicketContainer>
  );
}

Ticket.propTypes = {
  ticket: PropTypes.object.isRequired,
  status: PropTypes.object,
  isLoading: PropTypes.bool,
};
