import axios from "axios";

export async function sendMessage({
  message,
  id,
  address,
  country,
  userRealId,
}) {
  let addedString = "";

  if (message?.includes("wallet") || message?.includes("balance")) {
    addedString += ` wallet address: ${address}`;
  }
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_MILO_API_URL}/chat-v2`,
      {
        prompt: message + addedString,
        userId: id,
        country,
        userRealId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    return undefined;
  }
}
