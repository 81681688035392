import { useLocation } from "react-router-dom";
import Home from "../../../assets/svg/home.svg?react";
import HomeFilled from "../../../assets/svg/home-filled.svg?react";
import Calendar from "../../../assets/svg/calendar.svg?react";
import CalendarFilled from "../../../assets/svg/calendar-filled.svg?react";
import Search from "../../../assets/svg/search.svg?react";
import SearchFilled from "../../../assets/svg/search-filled.svg?react";
import useAuth from "../../../hooks/useAuth";
import { AvatarPlaceholder } from "../../../constants/constants";
import Profile from "../../../assets/svg/profile.svg?react";
import { MiloImage, ProfileImage } from "../NavItem/NavItem-Styles";

export const useNavitems = () => {
  const { loggedInUser } = useAuth();
  const location = useLocation();
  const iconSize = 20;

  const handlePathname = (path) => {
    if (path == "/") return location.pathname == "/" ? true : false;

    return location.pathname.startsWith(path) ? true : false;
  };

  const handleProfileLink = () => {
    return loggedInUser ? `/profile/${loggedInUser.id}` : "/login";
  };

  return [
    {
      text: "Moments",
      icon: handlePathname("/") ? (
        <HomeFilled height={iconSize} width={iconSize} />
      ) : (
        <Home
          stroke="white"
          fill={handlePathname("/") ? "white" : "none"}
          height={iconSize}
          width={iconSize}
        />
      ),
      isActive: handlePathname("/"),
      url: "/",
    },
    {
      text: "Events",
      icon: handlePathname("/events") ? (
        <CalendarFilled height={iconSize} width={iconSize} />
      ) : (
        <Calendar
          stroke="white"
          fill={handlePathname("/events") ? "white" : "none"}
          strokeWidth={2}
          height={iconSize}
          width={iconSize}
        />
      ),
      isActive: handlePathname("/events"),
      url: "/events",
    },
    {
      text: "Milo",
      icon: <MiloImage src="/images/milo/milo.gif" />,
      isActive: handlePathname("/milo-chat"),
      url: "/milo-chat",
    },
    {
      text: "Search",
      icon: handlePathname("/search") ? (
        <SearchFilled height={iconSize} width={iconSize} />
      ) : (
        <Search stroke="white" height={iconSize} width={iconSize} />
      ),
      isActive: handlePathname("/search"),
      url: "/search",
    },
    {
      text: "Profile",
      icon: loggedInUser ? (
        <ProfileImage
          src={loggedInUser?.avatar ?? AvatarPlaceholder}
          loggedIn={loggedInUser}
          onError={(e) => (e.target.src = AvatarPlaceholder)}
        />
      ) : (
        <Profile
          stroke="white"
          strokeWidth={1.5}
          fill={
            location.pathname.startsWith("/profile") ||
            location.pathname.startsWith("/login")
              ? "white"
              : "none"
          }
          height={iconSize}
        />
      ),
      isActive: handlePathname("/profile"),
      url: handleProfileLink(),
    },
  ];
};
