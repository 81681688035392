import { usePrivy, useWallets } from "@privy-io/react-auth";
import { useMemo } from "react";

export const useLinkedWallets = () => {
  const { wallets, ready: walletsReady } = useWallets();
  const { user, ready } = usePrivy();
  const isReady = ready && walletsReady;

  const linkedWallets = useMemo(
    () =>
      isReady &&
      user?.linkedAccounts.filter(
        (acc) => acc.type === "wallet" || acc.type === "smart_wallet"
      ),
    [user?.linkedAccounts, isReady]
  );

  //  Get all linked wallets and check if they are connected
  const updatedLinkedWallets = useMemo(() => {
    return (
      isReady &&
      linkedWallets?.map((linkedWallet) => {
        // Check if the linked wallet exists in the connected wallets array
        const isConnected = wallets?.some(
          (connectedWallet) => connectedWallet.address === linkedWallet.address
        );

        const isSmart = linkedWallet.type === "smart_wallet";

        // Return the linked wallet object if not connected
        return {
          ...linkedWallet,
          connected: isSmart ? true : isConnected,
        };
      })
    );
  }, [linkedWallets, wallets, isReady]);

  return { ready: isReady, linkedWallets: updatedLinkedWallets };
};
