import { useLogin, useLogout, usePrivy } from "@privy-io/react-auth";
import { useNavigate } from "react-router-dom";
import {
  confirmInviteFriendAction,
  getUserByPrivyId,
  insertUser,
} from "../api/profile";

import { isCinnyAuthenticated, isCinnySupported } from "../utils/cinny-utils";
import { identifyUser } from "../utils/fs-utils";
import useAuth from "./useAuth";
import useMixpanelTracker from "./useMixpanelTracker";
import mixpanel from "mixpanel-browser";

/**
 * Custom hook for handling login functionality
 * @returns {object} An object containing login-related properties and methods
 */
function useMomentifyLogin(inviteLinkId = null, invitorId = null) {
  const { ready, authenticated, user } = usePrivy();
  const { loggedInUser, setLoggedInUser } = useAuth();
  const navigate = useNavigate();
  const { handleTracking } = useMixpanelTracker();

  // Get login method
  const { login } = useLogin({
    // Callback when login is successful
    onComplete: async (user) => {
      try {
        // Fetch user data by Privy user id
        let fetchedUser = await getUserByPrivyId(user.id);

        // If user doesn't exist, create a new user and redirect to edit profile page
        if (!fetchedUser) {
          fetchedUser = await insertUser(user);

          if (inviteLinkId && invitorId) {
            await confirmInviteFriendAction({
              user_id: invitorId,
              action_id: inviteLinkId,
              target_id: fetchedUser.id,
            });
          }

          // Track sign up event
          handleTracking(
            "Sign Up",
            {
              "Signup Type": "App",
            },
            fetchedUser.id
          );
          // Set logged in user
          setLoggedInUser(fetchedUser);

          // Set logged in user id in local storage
          localStorage.setItem(
            "loggedInUserID",
            JSON.stringify(fetchedUser.id)
          );

          // Identify user
          identifyUser(fetchedUser);

          // Redirect to create profile page
          return navigate("/profile/create");
        }

        // If Cinny is supported and user is not authenticated in Cinny
        if (isCinnySupported() && !isCinnyAuthenticated()) {
          // Dynamically import federated Cinny utilities and initialize Matrix
          import("../utils/federated-cinny-utils").then(async (module) => {
            await module.initMatrix(fetchedUser);
          });
        }

        // Set logged in user
        setLoggedInUser(fetchedUser);
        // Set logged in user id in local storage
        localStorage.setItem("loggedInUserID", JSON.stringify(fetchedUser.id));

        // Identify user
        identifyUser(fetchedUser);
        // Redirect to profile
        navigate(`/profile/${fetchedUser.id}?from-login=true`);

        // Track login event
        handleTracking(
          "Login",
          {
            "Login Type": "App",
          },
          fetchedUser.id
        );
      } catch (error) {
        console.error(error);
      }
    },
    // Callback when login encounters an error
    onError: (error) => {
      console.error(error);
      // Clear logged in user
      setLoggedInUser(null);
    },
  });

  // Get logout method
  const { logout } = useLogout({
    // Callback when logout is successful
    onSuccess: () => {
      // Track logout event
      handleTracking(
        "Logout",
        {
          "Logout Type": "App",
        },
        localStorage.getItem("loggedInUserID")
      );
      // Clear logged in user
      setLoggedInUser(null);
      // Remove logged in user id from local storage
      localStorage.removeItem("loggedInUserID");
      localStorage.removeItem("milo-storage");
      // Redirect to home page
      navigate("/");
      mixpanel.reset();
    },
  });

  // Return login-related properties and methods
  return {
    ready,
    authenticated,
    user,
    loggedInUser,
    login,
    logout,
  };
}

export default useMomentifyLogin;
