import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  addOnboardArtists,
  followArtist,
  getFollowActionArtist,
  getSubscribeAction,
  subscribeToArtist,
  unfollowArtist,
  unsubscribeFromArtist,
} from "../../../api/activities";
import useNotification from "../../../hooks/useNotification";
import { artistLoader } from "../loader";
import {
  createAndGetArtists,
  getArtistCollectibles,
  getArtistDigitalCollectibles,
  getArtistMoments,
  getArtistPaidCollectibles,
} from "../../../api/artist";

export const useArtistQuery = ({ artistId, userId }) => {
  return useQuery({
    queryKey: [
      "artist",
      {
        artistId,
        userId,
      },
    ],
    queryFn: async () => {
      const data = await artistLoader({ artistId, userId });

      return data;
    },
  });
};

export const useArtistMomentsQuery = ({ artistId, userId }) => {
  return useQuery({
    queryKey: [
      "artist-moments",
      {
        artistId,
        userId,
      },
    ],
    queryFn: async () => {
      const moments = await getArtistMoments({ artistId, userId });

      if (!moments?.success) return [];

      return moments?.data || [];
    },
  });
};

export const useArtistCollectiblesQuery = ({ artistId }) => {
  return useQuery({
    queryKey: ["artist-collectibles", artistId],
    queryFn: async () => {
      const collectibles = await getArtistCollectibles({ artistId });

      if (!collectibles?.success) return [];

      return collectibles?.data || [];
    },
  });
};

export const useArtistPaidCollectiblesQuery = ({ artistId }) => {
  return useQuery({
    queryKey: ["artist-paid-collectibles", artistId],
    queryFn: async () => {
      const collectibles = await getArtistPaidCollectibles({ artistId });

      if (!collectibles?.success) return [];

      return collectibles?.data || [];
    },
  });
};

export const useArtistDigitalCollectiblesQuery = ({ artistId }) => {
  return useQuery({
    queryKey: ["artist-digital-collectibles", artistId],
    queryFn: async () => {
      const collectibles = await getArtistDigitalCollectibles({ artistId });

      if (!collectibles?.success) return [];

      return collectibles?.data || [];
    },
  });
};

export const useFollowMutate = ({ loggedInUserId, artistId }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["follow-artist"],
    mutationFn: async ({ isFollowing }) => {
      if (!isFollowing) await followArtist(loggedInUserId, artistId);
      else
        await getFollowActionArtist(loggedInUserId, artistId).then(
          async (res) => {
            if (res.success)
              await unfollowArtist(loggedInUserId, artistId, res?.data?.id);
          }
        );
    },
    onSuccess: (_, { isFollowing }) => {
      if (isFollowing) {
        toast.dismiss();
        toast.success("Unfollowed the artist");
      } else {
        toast.dismiss();
        toast.success("Followed artist");
      }

      queryClient.refetchQueries({
        queryKey: ["following", loggedInUserId.toString()],
      });

      // Update
      queryClient.invalidateQueries({
        queryKey: [
          "artist",
          {
            artistId: artistId.toString(),
            userId: loggedInUserId,
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: ["stats", loggedInUserId.toString()],
      });
    },
    onError: (error) => {
      toast.error("An error occurred. Please try again later." + error);
    },

    enabled: !!loggedInUserId && !!artistId,
  });
};

export const useSubscribeMutate = ({ loggedInUserId, artistId }) => {
  const queryClient = useQueryClient();
  const { notifiable_token } = useNotification();

  return useMutation({
    mutationKey: ["subscribe-artist"],
    mutationFn: async ({ isSubscribed, isFollowing }) => {
      if (!isSubscribed) {
        if (!isFollowing) await followArtist(loggedInUserId, artistId);
        await subscribeToArtist(loggedInUserId, artistId, notifiable_token);
      } else
        await getSubscribeAction(loggedInUserId, artistId).then(async (res) => {
          if (res.success)
            await unsubscribeFromArtist(
              loggedInUserId,
              artistId,
              res?.data?.id,
              notifiable_token
            );
        });
    },
    onSuccess: (_, { isSubscribed }) => {
      if (isSubscribed) {
        toast.dismiss();
        toast.success("Unsubscribed from artist, XP removed");
      } else {
        toast.dismiss();
        toast.success("Subscribed to artist + 2xp");
      }

      queryClient.refetchQueries({
        queryKey: ["following", loggedInUserId.toString()],
      });

      // Update
      queryClient.invalidateQueries({
        queryKey: [
          "artist",
          {
            artistId: artistId.toString(),
            userId: loggedInUserId,
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: ["stats", loggedInUserId.toString()],
      });
    },
    onError: (error) => {
      toast.error("An error occurred. Please try again later." + error);
    },

    enabled: !!loggedInUserId && !!artistId,
  });
};

export const useSubscribeOnboardMutate = ({ loggedInUserId, artistIds }) => {
  return useMutation({
    mutationKey: ["subscribe-artist-onboard"],
    mutationFn: async () => {
      try {
        // Create an array of promises for following and subscribing to artists
        const followAndSubscribePromises = artistIds.flatMap((id) => [
          followArtist(loggedInUserId, id),
          // subscribeToArtist(loggedInUserId, id, notifiable_token),
        ]);

        // Await all follow and subscribe promises concurrently
        await Promise.all(followAndSubscribePromises);

        // Add onboard artists
        const result = await addOnboardArtists(loggedInUserId, artistIds);

        // Check the result and show a success message if applicable
        if (result.success) {
          toast.success(`Added favorite artists +${result?.data?.xp?.xp}xp`);
        }
      } catch (error) {
        // Handle any errors that occur during the process
        console.error("An error occurred while processing:", error);
        toast.error("Failed to add favorite artists.");
      }
    },

    enabled: !!loggedInUserId && !!artistIds,
  });
};

export const useGetAndCreateArtists = () => {
  return useMutation({
    mutationKey: ["create-and-get-artists"],
    mutationFn: async (artists) => {
      try {
        const ids = await createAndGetArtists(artists);

        return ids;
      } catch (error) {
        // Handle any errors that occur during the process
        console.error("An error occurred while processing:", error);
        toast.error("Failed to retrieve artist ids.");
      }
    },
  });
};

export const useSpotifySubscribe = () => {
  const { notifiable_token } = useNotification();
  return useMutation({
    mutationKey: ["spotify-artist-onboard"],
    mutationFn: async ({ loggedInUserId, artistIDs }) => {
      try {
        // Create an array of promises for following and subscribing to artists
        const followAndSubscribePromises = artistIDs.flatMap((id) => [
          followArtist(loggedInUserId, id),
          subscribeToArtist(loggedInUserId, id, notifiable_token),
        ]);

        // Await all follow and subscribe promises concurrently
        await Promise.all(followAndSubscribePromises);

        toast.success(
          `Subscribed to spotify artists +${2 * artistIDs.length}xp`
        );
      } catch (error) {
        // Handle any errors that occur during the process
        console.error("An error occurred while processing:", error);
        toast.error("Failed to add spotify artists.");
      }
    },
  });
};
