import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.blue};
  min-width: 310px;
  max-width: 310px;
  margin: 0 auto;
`;

export const BalanceContainer = styled.div`
  padding: 16px 0 8px;
`;
