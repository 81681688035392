import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  transition: 0.2s ease-in-out;
  opacity: ${({ active, isMilo }) => (active || isMilo ? 1 : 0.6)};
  flex: 1;
  padding-bottom: 15px;

  span {
    color: ${({ active }) => active && "white"};
    font-weight: ${({ active }) => active && 600};
  }
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-size: cover;
  background-position: center;
  opacity: ${({ loggedIn }) => (loggedIn ? 1 : 0.6)};
  object-fit: cover;
`;

export const MiloImage = styled.img`
  border-radius: 50%;
  height: 32px;
  width: 32px;
  object-fit: cover;
  position: relative;
  top: -5px;
`;
