import { Group, Progress, Stack, Text } from "@mantine/core";
import PropTypes from "prop-types";

export const ProgressBar = ({ totalSales, totalTickets, totalValue }) => {
  const salesPercentage = isNaN(totalSales / totalTickets)
    ? 0
    : (totalSales / totalTickets) * 100;
  const formattedSalesPercentage = salesPercentage.toFixed(2);

  return (
    <Stack gap={12}>
      <Text
        style={{
          fontFamily: "Suisse Intl",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "100%",
          color: "white",
        }}
      >
        Total ticket sales
      </Text>

      <Progress
        value={salesPercentage}
        styles={{
          section: {
            backgroundColor: "#6EDBBB",
          },
          root: {
            backgroundColor: "#FFFFFF1F",
            height: "8px",
          },
        }}
      />
      <Group gap={16}>
        <Text
          style={{
            fontFamily: "Suisse Intl",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "100%",
            color: "#F7F7F7",
          }}
        >
          {totalSales ?? 0}/{totalTickets ?? 0}
        </Text>
        <Text
          style={{
            fontFamily: "Suisse Intl",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "100%",
            color: "#99999D",
          }}
        >
          {formattedSalesPercentage}%
        </Text>
        <Text
          style={{
            fontFamily: "Suisse Intl",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "100%",
            color: "#F7F7F7",
            marginLeft: "auto",
          }}
        >
          ${isNaN(totalValue) ? "0.00" : totalValue.toFixed(2)}
        </Text>
      </Group>
    </Stack>
  );
};

ProgressBar.propTypes = {
  totalSales: PropTypes.number.isRequired,
  totalTickets: PropTypes.number.isRequired,
  totalValue: PropTypes.number.isRequired,
};
