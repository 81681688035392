import { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { BodyText } from "../GlobalStyles";

const Container = styled.div`
  overflow: hidden;
  position: relative;
  line-height: normal;
`;

const ToggleButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: fit-content;
`;

export default function ExpandableText({
  text,
  maxLength = 100,
  isSecondary,
  isDescription,
}) {
  const [showAll, setShowAll] = useState(false);

  const displayedText =
    showAll || text?.length < maxLength
      ? text
      : maxLength === 0
        ? ""
        : `${text.slice(0, maxLength)}...`;

  const toggleText = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      <Container showAll={showAll}>
        <BodyText
          secondary={isSecondary}
          style={{
            fontSize: isDescription ? 12 : 14,
          }}
        >
          {displayedText}
        </BodyText>
      </Container>
      {text.length > maxLength && (
        <ToggleButton onClick={toggleText}>
          <BodyText
            secondary
            style={{
              fontSize: isDescription ? 12 : 14,
            }}
          >
            {showAll
              ? "See less"
              : isDescription
                ? "See description"
                : "Read more"}
          </BodyText>
        </ToggleButton>
      )}
    </>
  );
}

ExpandableText.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  isSecondary: PropTypes.bool,
  isDescription: PropTypes.bool,
};
