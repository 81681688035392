import { useQuery } from "@tanstack/react-query";
import {
  getMintedTicket,
  getUserTicketsToTransfer,
} from "../../../api/ticketing";
import { searchUsers } from "../../../api/user";

export const useGetMintedTicket = ({ userId, mintId }) => {
  return useQuery({
    queryKey: ["minted_ticket", { userId, mintId }],
    queryFn: async () => {
      return getMintedTicket({ userId, mintId });
    },
  });
};

export const useSearchUsers = ({ query, ownerId }) => {
  return useQuery({
    queryKey: ["search_users", { query, ownerId }],
    queryFn: async () => {
      return searchUsers({ query, ownerId });
    },
  });
};
export const useUserTicketsToTransfer = ({ userId }) => {
  return useQuery({
    queryKey: ["tickets-to-transfer", userId],
    queryFn: async () => {
      const { data } = await getUserTicketsToTransfer({ userId });

      return data;
    },
  });
};
