import { useLinkAccount } from "@privy-io/react-auth";
import { Container } from "./LinkWallets-Styles";
import { Button, BodyText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import Plus from "../../../assets/svg/plus.svg?react";
import useAuth from "../../../hooks/useAuth";

export default function LinkWallets() {
  const { loggedInUser, updateCurrentUser } = useAuth();
  const { linkWallet } = useLinkAccount({
    onSuccess: (user) => {
      const walletAddresses = user?.linkedAccounts
        .filter((acc) => acc.type === "wallet" || acc.type === "smart_wallet")
        .map((wallet) => wallet.address);

      if (loggedInUser) {
        updateCurrentUser({ wallet_addresses: walletAddresses });
      }
    },
  });

  return (
    <Container onClick={linkWallet}>
      <Button>
        <Plus height={16} stroke="white" />
      </Button>
      <BodyText color={theme.colors.grey}>Add another wallet</BodyText>
    </Container>
  );
}
