import { useMutation, useQuery } from "@tanstack/react-query";
import {
  generateTicketReferral,
  getPromoterDetails,
  getTicketBalance,
  getTicketContract,
  insertReferralVisit,
} from "../../../api/ticketing";
import { useEffect } from "react";

export const usePromoterDetails = ({ eventId, referralCode }) => {
  return useQuery({
    queryKey: ["promoter-details"],
    queryFn: async () => {
      const data = await getPromoterDetails({ eventId, referralCode });

      return data.data;
    },
    enabled: !!eventId && !!referralCode,
  });
};

export const useTicketBalance = ({ walletAddress }) => {
  return useQuery({
    queryKey: ["ticket-balance"],
    queryFn: async () => {
      const data = await getTicketBalance({ walletAddress });
      return data;
    },
  });
};

export const useGenerateTicketReferral = ({ eventId, userId }) => {
  return useQuery({
    queryKey: ["generate-ticket-referral", eventId, userId], // Include variables in query key
    queryFn: async () => {
      const data = await generateTicketReferral({ eventId, userId });
      return data;
    },
    enabled: !!eventId && !!userId,
  });
};
export const useInsertReferralVisit = () => {
  return useMutation({
    mutationKey: ["insert-referral-visit"],
    mutationFn: async ({ userId, referralCode }) => {
      const data = await insertReferralVisit({ userId, referralCode });

      return data;
    },
  });
};

export const useHandleReferralVisit = (userId, referralCode) => {
  const { mutateAsync: insertReferralVisit, isPending } =
    useInsertReferralVisit();

  useEffect(() => {
    if (userId && referralCode && !isPending) {
      insertReferralVisit({
        userId,
        referralCode,
      });
    }
  }, [userId, referralCode]);
};

export const useTicketContract = ({ eventId }) => {
  return useQuery({
    queryKey: ["ticket-contract", eventId],
    queryFn: async () => {
      const data = await getTicketContract({ eventId });
      return data;
    },
  });
};
