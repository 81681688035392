import useAuth from "../../../hooks/useAuth";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { UserTicketsContainer } from "../../Profile/UserTickets/UserTickets-Styles";
import TicketItem from "../../Tickets/TicketItem";
import TopNavV2 from "../../TopNavV2";
import { Container } from "./SelectTicketTransfer-Styles";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { useTicketTransferStore } from "../../../routes/TicketTransfer/stores/ticketTransferStore";
import { useUserTicketsToTransfer } from "../../../routes/TicketTransfer/hooks/ticketTransferQueries";
import TicketTransferSummary from "../../TicketTransfer/TicketTransferSummary";
import { theme } from "../../../utils/theme";
import { useEffect } from "react";
import EmptyState from "../../EmptyState";

export default function SelectTicketTransfer() {
  const { loggedInUser } = useAuth();
  const { closeSheetModal } = useGlobalModal();
  const {
    ticket: selectedTicket,
    setTicket,
    reset,
    setIsModal,
    setIsSelectingTickets,
  } = useTicketTransferStore();
  const { data, isLoading } = useUserTicketsToTransfer({
    userId: loggedInUser?.id ?? "",
  });

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    if (!data || !data?.length) return;

    setIsModal(true);
    setIsSelectingTickets(true);
  }, [data]);

  const handleOpacity = (ticket) => {
    return selectedTicket?.id !== ticket?.id && selectedTicket ? 0.6 : 1;
  };

  const handleClose = () => {
    closeSheetModal();
    reset();
  };

  const handleSelect = (ticket) => {
    if (selectedTicket && selectedTicket?.id !== ticket?.id) return;

    if (selectedTicket?.id === ticket?.id) return setTicket(null);

    setTicket(ticket);
  };

  if (isLoading) {
    return (
      <>
        <TopNavV2
          title="Select a ticket to transfer"
          onBack={handleClose}
          isSticky
          isBlurred
          style={{
            paddingTop: 0,
            paddingBottom: 4,
            background: theme.colors.background,
          }}
        />
        <Container>
          <UserTicketsContainer>
            {Array.from({ length: 12 }).map((_, index) => (
              <SkeletonElement
                key={index}
                width="100%"
                height="125px"
                radius="10px"
              />
            ))}
          </UserTicketsContainer>
        </Container>
      </>
    );
  }

  return (
    <>
      <TopNavV2
        title="Select a ticket to transfer"
        onBack={handleClose}
        isSticky
        isBlurred
        style={{
          paddingTop: 0,
          paddingBottom: 4,
          background: theme.colors.background,
        }}
      />
      {data?.length > 0 ? (
        <>
          <Container>
            <UserTicketsContainer>
              {data?.map((ticket) => (
                <div
                  key={ticket?.id}
                  onClick={() => handleSelect(ticket)}
                  style={{
                    cursor: "pointer",
                    opacity: handleOpacity(ticket),
                  }}
                >
                  <TicketItem ticket={ticket} />
                </div>
              ))}
            </UserTicketsContainer>
          </Container>
          <TicketTransferSummary />
        </>
      ) : (
        <EmptyState
          title="No available tickets"
          description="You can buy a ticket first"
          style={{
            paddingBottom: "50%",
          }}
        />
      )}
    </>
  );
}
