import { useRetroFlowStore } from "../../../routes/Search/hooks/searchStore";
import { useMiloStore } from "../../Milo/stores/miloStore";
import { FooterLink } from "./NavItem-Styles";
import PropTypes from "prop-types";

export default function NavItem({ icon, isActive, url }) {
  const { setIsOpen } = useRetroFlowStore();

  const { setIsQuickMessage } = useMiloStore();

  const handleClick = () => {
    setIsOpen(false);
    setIsQuickMessage(false);
  };

  return (
    <FooterLink
      to={url}
      active={isActive}
      onClick={handleClick}
      isMilo={url === "/milo-chat"}
    >
      {icon}
    </FooterLink>
  );
}

NavItem.propTypes = {
  icon: PropTypes.any,
  isActive: PropTypes.bool,
  url: PropTypes.string,
};
