import { Group, Paper, Text } from "@mantine/core";
import PropTypes from "prop-types";

export const UserMessage = ({ message }) => {
  return (
    <Group align="right" gap="xs" justify="flex-end">
      <Paper
        style={{
          maxWidth: "80%",
          padding: "8px 12px",
          backgroundColor: "#1E1E37",
          borderRadius: "12px 12px 4px 12px",
        }}
      >
        <Text
          style={{
            color: "#FFFFFF",
            fontSize: "14px",
            fontFamily: "Suisse Intl",
            fontWeight: 400,
          }}
        >
          {message}
        </Text>
      </Paper>
    </Group>
  );
};

UserMessage.propTypes = {
  message: PropTypes.string,
};
