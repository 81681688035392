import {
  ChainIconContainer,
  Image,
  ImageContainer,
} from "../../../routes/Collectible/Collectible-Styles";
import PropTypes from "prop-types";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { GlassmorphPlaceholder } from "../../../constants/constants";
import BaseIcon from "../../../assets/svg/base.svg?react";
import CollectibleVideo from "../CollectibleVideo";

export default function CollectibleAsset({ image, video, chain, isLoading }) {
  if (isLoading) {
    return <SkeletonElement height="350px" width="100%" radius="8px" />;
  }

  return (
    <ImageContainer>
      {video?.url ? (
        <CollectibleVideo video={video?.url} />
      ) : (
        <Image
          src={image}
          alt="collectible"
          onError={(e) => (e.target.src = GlassmorphPlaceholder)}
        />
      )}
      {chain === "base" ? (
        <ChainIconContainer>
          <BaseIcon />
        </ChainIconContainer>
      ) : null}
    </ImageContainer>
  );
}

CollectibleAsset.propTypes = {
  image: PropTypes.string,
  video: PropTypes.object,
  chain: PropTypes.string,
  isLoading: PropTypes.bool,
};
