import { create } from "zustand";

export const useTicketTransferStore = create((set) => ({
  ticket: null,
  setTicket: (ticket) => set(() => ({ ticket })),
  isSelectingTickets: false,
  setIsSelectingTickets: (value) => set(() => ({ isSelectingTickets: value })),
  selectedUsers: [],
  toggleSelectedUser: (user) =>
    set(({ selectedUsers }) => ({
      selectedUsers: selectedUsers.find((u) => u.id === user.id)
        ? selectedUsers.filter((u) => u.id !== user.id)
        : [...selectedUsers, user],
    })),
  clearSelectedUsers: () => {
    set(() => ({
      selectedUsers: [],
    }));
  },
  isModal: false,
  setIsModal: (value) => set(() => ({ isModal: value })),
  prompt: null,
  setPrompt: (prompt) => set(() => ({ prompt })),
  reset: () => {
    set(() => ({
      ticket: null,
      selectedUsers: [],
      isSelectingTickets: false,
      isModal: false,
      prompt: null,
    }));
  },
}));
