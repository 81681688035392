import { createGlobalStyle, styled } from "styled-components";
import { theme } from "./utils/theme";

export const GlobalStyles = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		
		-ms-overflow-style: none;  /* IE and Edge */
  		scrollbar-width: none;  /* Firefox */
		::-webkit-scrollbar {
			display: none;
		}
	}
	
	a {
		text-decoration: none !important;
    color: ${({ theme }) => theme.colors.white} !important;
	}

  input {
    font-family: ${({ theme }) => theme.fontStyles.suisse};
    font-size: ${({ theme }) => theme.fontSizes.mobile.body};
  }

	button {
		cursor: pointer;
	}

	#root {
		overflow-y: scroll;
	}

	:root {
		select {
			background-color: transparent;
		}

    --toastify-color-dark: #242428;
    --toastify-font-family: "Suisse Intl", sans-serif;
	}

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { 
    display: none; 
  }

  .react-md {
    color: ${({ theme }) => theme.colors.grey} !important;
    font-size: 14px !important;
    font-family: Suisse Intl !important;
    background: transparent;
  }

  .react-md a {
    color: ${({ theme }) => theme.colors.white} !important;
    text-decoration: underline !important;
  }

  [data-testid="ockWalletBottomSheet"] {
    z-index: 9999 !important;
  }

  .reactEasyCrop_Image {
    object-fit: cover;
  }

  .react-modal-sheet-header {
    opacity: 0.3 !important
  }

  .react-modal-sheet-drag-indicator {
    width: 30px !important;
  }
`;

export const Section = styled.section`
  flex: 1;
  background: ${({ theme }) => theme.colors.backgroundOverlay};
  background-color: rgba(17, 17, 31, 1);
  background-blend-mode: multiply;
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  font-size: ${({ theme }) => theme.fontSizes.mobile.body};
  color: ${(props) => props.color ?? props.theme.colors.white};
`;

export const HeaderXxxxl = styled.h1`
  font-family: ${({ theme }) => theme.fontStyles.druk};
  font-weight: ${({ weight }) => weight ?? 900};
  font-size: ${({ theme }) => theme.fontSizes.mobile.headerXxxxl};
  color: ${({ color, theme }) => color ?? theme.colors.white};
  line-height: ${({ lineHeight, theme }) =>
    lineHeight ?? theme.lineHeight.headerXxxxl};
  letter-spacing: ${({ letterSpacing, theme }) =>
    letterSpacing ?? theme.letterSpacing.headerXxxxl};
`;

export const HeaderXxxl = styled.h1`
  font-family: ${({ theme }) => theme.fontStyles.druk};
  font-weight: ${({ weight }) => weight ?? 900};
  font-size: ${({ theme }) => theme.fontSizes.mobile.headerXxxl};
  color: ${({ color, theme }) => color ?? theme.colors.white};
  line-height: ${({ lineHeight, theme }) =>
    lineHeight ?? theme.lineHeight.headerXxxl};
  letter-spacing: ${({ letterSpacing, theme }) =>
    letterSpacing ?? theme.letterSpacing.headerXxxl};
  ${({ truncate }) =>
    truncate &&
    `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
`;

export const HeaderXxl = styled.h1`
  font-family: ${({ theme }) => theme.fontStyles.druk};
  font-weight: ${({ weight }) => weight ?? 900};
  font-size: ${({ theme }) => theme.fontSizes.mobile.headerXxl};
  color: ${({ color, theme }) => color ?? theme.colors.white};
  line-height: ${({ lineHeight, theme }) =>
    lineHeight ?? theme.lineHeight.headerXxl};
  letter-spacing: ${({ letterSpacing, theme }) =>
    letterSpacing ?? theme.letterSpacing.headerXxl};
  ${({ truncate }) =>
    truncate &&
    `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
`;
export const HeaderXl = styled.h1`
  font-family: ${({ theme }) => theme.fontStyles.druk};
  font-weight: ${({ weight }) => weight ?? 900};
  font-size: ${({ theme }) => theme.fontSizes.mobile.headerXl};
  color: ${({ color, theme }) => color ?? theme.colors.white};
  line-height: ${({ lineHeight, theme }) =>
    lineHeight ?? theme.lineHeight.headerXl};
  letter-spacing: ${({ letterSpacing, theme }) =>
    letterSpacing ?? theme.letterSpacing.headerXl};
  ${({ truncate }) =>
    truncate &&
    `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
`;

export const HeaderL = styled.h2`
  font-family: ${({ theme }) => theme.fontStyles.druk};
  font-weight: ${({ weight }) => weight ?? 900};
  font-size: ${theme.fontSizes.mobile.headerL};
  color: ${({ theme, color }) => color ?? theme.colors.white};
  line-height: ${({ theme, lineHeight }) =>
    lineHeight ?? theme.lineHeight.headerL};
  letter-spacing: ${({ letterSpacing, theme }) =>
    letterSpacing ?? theme.letterSpacing.headerL};
  ${({ truncate }) =>
    truncate &&
    `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
`;

export const Header = styled.h3`
  font-family: ${({ theme }) => theme.fontStyles.druk};
  font-weight: ${({ weight }) => weight ?? 900};
  font-size: ${({ theme }) => theme.fontSizes.mobile.header};
  color: ${({ theme, color }) => color ?? theme.colors.white};
  line-height: ${({ theme, lineHeight }) =>
    lineHeight ?? theme.lineHeight.header};
  letter-spacing: ${({ letterSpacing, theme }) =>
    letterSpacing ?? theme.letterSpacing.header};
  ${({ truncate }) =>
    truncate &&
    `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
`;

export const HeaderSm = styled.h3`
  font-family: ${({ theme }) => theme.fontStyles.druk};
  font-weight: ${({ weight }) => weight ?? 900};
  font-size: ${({ theme }) => theme.fontSizes.mobile.headerSm};
  color: ${({ theme, color }) => color ?? theme.colors.white};
  line-height: ${({ theme, lineHeight }) =>
    lineHeight ?? theme.lineHeight.headerSm};
  letter-spacing: ${({ letterSpacing, theme }) =>
    letterSpacing ?? theme.letterSpacing.headerSm};
  ${({ truncate }) =>
    truncate &&
    `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
`;

export const Title = styled.span`
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  font-weight: ${({ weight }) => weight ?? 600};
  font-size: ${({ theme }) => theme.fontSizes.mobile.title};
  color: ${({ theme, color }) => color ?? theme.colors.white};
  line-height: ${({ theme, lineHeight }) =>
    lineHeight ?? theme.lineHeight.title};
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? "normal"};
  ${({ truncate }) =>
    truncate &&
    `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
`;

export const Subheader = styled.span`
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  font-weight: ${({ weight }) => weight ?? 600};
  font-size: ${({ theme }) => theme.fontSizes.mobile.subheader};
  color: ${({ theme, color, secondary }) =>
    secondary ? theme.colors.grey : (color ?? theme.colors.white)};
  line-height: ${({ theme, lineHeight }) =>
    lineHeight ?? theme.lineHeight.subheader};
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? "normal"};
  ${({ truncate }) =>
    truncate &&
    `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
`;

export const BodyText = styled.span`
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  font-weight: ${({ weight, bold }) => (weight ? weight : bold ? 600 : 400)};
  font-size: ${({ size, theme }) => size ?? theme.fontSizes.mobile.body};
  color: ${({ theme, color, secondary }) =>
    secondary ? theme.colors.grey : (color ?? theme.colors.white)};
  line-height: ${({ theme, lineHeight }) =>
    lineHeight ?? theme.lineHeight.body};
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? "normal"};
  ${({ truncate }) =>
    truncate &&
    `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
`;

export const FooterText = styled.span`
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  font-weight: ${({ weight, bold }) => (weight ? weight : bold ? 600 : 400)};
  font-size: ${({ theme }) => theme.fontSizes.mobile.footer};
  color: ${({ theme, color, secondary }) =>
    secondary ? theme.colors.grey : (color ?? theme.colors.white)};
  line-height: ${({ theme, lineHeight }) =>
    lineHeight ?? theme.lineHeight.footer};
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? "normal"};
  ${({ truncate }) =>
    truncate &&
    `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
`;

export const FooterTextSm = styled.span`
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  font-weight: ${({ weight, bold }) => (weight ? weight : bold ? 600 : 400)};
  font-size: ${({ theme }) => theme.fontSizes.mobile.footerSm};
  color: ${({ theme, color, secondary }) =>
    secondary ? theme.colors.grey : (color ?? theme.colors.white)};
  line-height: ${({ theme, lineHeight }) =>
    lineHeight ?? theme.lineHeight.footerSm};
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? "normal"};
  ${({ truncate }) =>
    truncate &&
    `overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;`}
`;

export const DrukSpan = styled.span`
  font-family: ${theme.fontStyles.druk};
  font-weight: ${(props) => props.weight ?? 500};
  font-size: ${(props) => props.size ?? props.theme.fontSizes.mobile.body};
  color: ${(props) => props.color ?? "white"};
  letter-spacing: 0.6px;
`;

export const DrukWideSpan = styled.span`
  font-family: ${theme.fontStyles.drukWide};
  font-weight: ${(props) => props.weight ?? 500};
  font-size: ${(props) => props.size ?? props.theme.fontSizes.mobile.body};
  color: ${(props) => props.color ?? "white"};
  line-height: ${(props) => props.lineheight ?? 1.2};
`;

export const Button = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  padding: 8px;
  border-radius: 10px;
  border: ${({ theme, primary, green }) =>
    primary || green ? "none" : `1px solid ${theme.colors.border}`};
  background: ${({ theme, background, primary, green }) =>
    primary
      ? theme.colors.greyBtn
      : green
        ? theme.colors.green
        : (background ?? theme.colors.blackTransparent)};
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  color: white;
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  font-size: ${({ theme }) => theme.fontSizes.mobile.body};
  color: ${({ theme, green }) =>
    green ? theme.colors.black : theme.colors.white};
  font-weight: 600;

  &:disabled {
    opacity: 0.6;
  }
`;

export const ButtonSm = styled(Button)`
  height: 30px;
  width: 30px;
`;

export const ButtonL = styled(Button)`
  height: 37px;
  width: 37px;
`;

export const ButtonGhost = styled(Button)`
  background: transparent;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  border: none;
`;

export const ButtonGhostL = styled(ButtonL)`
  background: transparent;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  border: none;
`;

export const ButtonRect = styled(Button)`
  min-height: 37px;
  width: fit-content;
  padding: 8px 24px;
  gap: 12px;
  background: ${({ theme, primary, green }) =>
    primary
      ? theme.colors.greyBtn
      : green
        ? theme.colors.green
        : theme.colors.newBtn};
  border: none;
`;

export const ButtonRectSm = styled(ButtonRect)`
  min-height: 32px;
  padding: 8px 16px;
  font-size: ${({ theme }) => theme.fontSizes.mobile.footer};
`;

export const ButtonRectXs = styled(ButtonRect)`
  min-height: 26px;
  height: 26px;
  max-height: 26px;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: ${({ theme }) => theme.fontSizes.mobile.footerSm};
`;

export const ButtonLarge = styled.button`
  background: ${(props) => props.background ?? props.theme.colors.green};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9.5px 16px;
`;

export const IconWithText = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "12px")};
`;

export const InvisibleInputImage = styled.input`
  height: 100%;
  width: 100%;
  z-index: 99;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export const HorizontalScrollableContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  gap: 12px;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-right: 1rem;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const OneLineText = styled(BodyText)`
  /* 1 line only then put ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Line = styled.hr`
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  opacity: 0.5;
  margin: 12px 0;
  width: 100%;
  align-self: center;
`;
