import { ButtonRectSm } from "../../../GlobalStyles";
import Plane from "../../../assets/svg/plane.svg?react";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
import PropTypes from "prop-types";
import { useMiloStore } from "../stores/miloStore";
import moment from "moment";
import { transferTicketMinted } from "../../../api/ticketing";
import { useTicketTransferStore } from "../../../routes/TicketTransfer/stores/ticketTransferStore";
import { sendMessage } from "../../../api/milo";
import { useState } from "react";

export default function ConfirmTransferTicketButton({ ticket, prompt }) {
  const { loggedInUser, login } = useMomentifyLogin();
  const { messages, setMessages } = useMiloStore();
  const {
    selectedUsers,
    reset,
    ticket: selectedTicket,
  } = useTicketTransferStore();
  const [isTransferring, setIsTransferring] = useState(false);
  const [transferred, setTransferred] = useState(false);

  const handleTransfer = async () => {
    if (!loggedInUser) return login();

    if (!prompt) return;

    let convo = messages;

    try {
      setIsTransferring(true);
      const {
        id: loggedInUserId,
        mpc_address,
        loggedInUserWallet,
      } = loggedInUser || {};
      const {
        id: mintId,
        ticket_id,
        tickets_contract_id,
        user_wallet,
        type,
        headline_artist,
        date,
      } = ticket || {};
      const {
        id: userId,
        receiving_wallet,
        username,
      } = selectedUsers?.[0] || {};

      const formattedDate = moment(date).utc().format("MMM DD");

      convo.push(
        {
          type: "user",
          content: `I confirm. Transfer 1 ${type} ticket for ${headline_artist}’s event on ${formattedDate} to @${username}.`,
        },
        {
          type: "bot",
          content: `Transferring ticket to @${username}...`,
        }
      );

      setMessages(convo);

      const {
        data,
        message,
        success: transactionSuccess,
      } = (await sendMessage({ message: prompt, id: loggedInUserId })) || {};

      const regex = /0x[a-fA-F0-9]{64}/;
      const match = message?.match(regex);

      if (!match && !transactionSuccess && !data) {
        convo.push({
          type: "bot",
          content: `Woops! Something went wrong. Please try again later.`,
        });
        setMessages(convo);
        return console.error("Failed to transfer ticket in Blockchain");
      }

      const { success, error } =
        (await transferTicketMinted({
          sender: {
            id: loggedInUserId,
            wallet: user_wallet ?? mpc_address ?? loggedInUserWallet,
          },
          receiver: {
            id: userId,
            wallet: receiving_wallet,
          },
          ticket: {
            id: ticket_id,
            mintId: mintId,
            contractId: tickets_contract_id,
          },
        })) || {};

      if (!success || error) {
        convo.push({
          type: "bot",
          content: `Woops! Something went wrong. Please try again later.`,
        });
        setMessages(convo);
        return console.error("Failed to transfer ticket in DB side", error);
      }

      convo.push({
        type: "bot",
        content: `Congrats! You successfully transferred 1 ${type} ticket for ${headline_artist}’s event on ${formattedDate}!`,
      });

      setMessages(convo);

      setTransferred(true);
      reset();
    } catch (err) {
      convo.push({
        type: "bot",
        content: `Woops! Something went wrong. Please try again later.`,
      });
      setMessages(convo);
      console.error("Failed to transfer ticket", err);
    } finally {
      setIsTransferring(false);
    }
  };

  if (!prompt) return null;

  return (
    <ButtonRectSm
      style={{
        marginLeft: 48,
      }}
      green
      onClick={handleTransfer}
      disabled={
        isTransferring ||
        transferred ||
        !selectedUsers?.length ||
        selectedTicket?.id !== ticket?.id
      }
    >
      <Plane height={16} width={16} stroke="black" strokeWidth={1.5} />
      {transferred
        ? "Transferred"
        : isTransferring
          ? "Transferring..."
          : "Transfer"}
    </ButtonRectSm>
  );
}

ConfirmTransferTicketButton.propTypes = {
  ticket: PropTypes.object,
  prompt: PropTypes.string,
};
