import { Avatar, Group, Paper, Text } from "@mantine/core";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
// interface BotMessageProps {
//   message: string;
//   children?: React.ReactNode;
//   showAvatar?: boolean;
//   isTyping?: boolean;
// }

export const BotMessage = ({
  message,
  children,
  showAvatar = true,
  isTyping = false,
}) => {
  const typingVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: {
      opacity: 1,
      height: "auto",
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.02,
      },
    },
  };

  const letterVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const isYesOrNo = message?.toLowerCase()?.includes("is this correct?");
  const finalMessage = isYesOrNo ? message?.replaceAll("-", "● ") : message;
  return (
    <Group align="start" gap="xs">
      <Avatar
        src="/images/milo/milo.gif"
        style={{
          visibility: showAvatar ? undefined : "hidden",
        }}
      />

      <Paper
        component={motion.div}
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        style={{
          maxWidth: "80%",
          padding: "8px 12px",
          backgroundColor: "#2C2C3D",
          borderRadius: "4px 12px 12px 12px",
          flexGrow: 1,
        }}
      >
        {showAvatar && (
          <Text
            style={{
              color: "#7A79FF",
              fontSize: "14px",
              fontFamily: "Suisse Intl",
              fontWeight: 600,
              marginBottom: 2,
            }}
          >
            Milo
          </Text>
        )}
        <motion.div
          variants={typingVariants}
          initial="hidden"
          animate="visible"
        >
          {isTyping ? (
            <Text
              style={{
                color: "#FFFFFF",
                fontSize: "14px",
                fontFamily: "Suisse Intl",
                fontWeight: 400,
                whiteSpace: "pre-wrap",
              }}
            >
              {message?.split("").map((char, index) => (
                <motion.span key={index} variants={letterVariants}>
                  {char}
                </motion.span>
              ))}
            </Text>
          ) : (
            <Text
              style={{
                color: "#FFFFFF",
                fontSize: "14px",
                fontFamily: "Suisse Intl",
                fontWeight: 400,
                whiteSpace: "pre-wrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {finalMessage
                ?.split(/(<HIGHLIGHT>.*?<\/HIGHLIGHT>)/g)
                ?.map((part, index) =>
                  part.startsWith("<HIGHLIGHT>") &&
                  part.endsWith("</HIGHLIGHT>") ? (
                    <span key={index} style={{ color: "#6EDBBB" }}>
                      {part.replace(/<\/?HIGHLIGHT>/g, "")}
                    </span>
                  ) : (
                    part
                  )
                )}
            </Text>
          )}
        </motion.div>
        {children}
      </Paper>
    </Group>
  );
};

BotMessage.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
  showAvatar: PropTypes.bool,
  isTyping: PropTypes.bool,
};
