import PropTypes from "prop-types";
import { BodyText, FooterText, Header } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { formatPrice } from "../../../utils/utils";
import { TicketDetailsContainer, TicketFees } from "./Ticket-Styles";

export default function TicketDetails({ price, fee }) {
  const formattedPrice = formatPrice(price);
  const formattedFee = formatPrice(fee, false);

  return (
    <TicketDetailsContainer>
      <TicketFees>
        <TicketFees style={{ gap: "0px" }}>
          <Header
            style={{
              fontFamily: theme.fontStyles.suisse,
              fontWeight: 600,
            }}
          >
            ${formattedPrice[0]}
          </Header>
          <BodyText
            style={{
              marginBottom: "1px",
              fontSize: "16px",
              fontFamily: theme.fontStyles.suisse,
              fontWeight: 600,
            }}
          >
            .{formattedPrice[1] ?? "00"}
          </BodyText>
        </TicketFees>
        <FooterText color={theme.colors.grey}>
          +${price > 0 ? formattedFee : "0.00"} fee
        </FooterText>
      </TicketFees>
    </TicketDetailsContainer>
  );
}

TicketDetails.propTypes = {
  price: PropTypes.number,
  fee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
