import { toast } from "react-toastify";

const formatTicketPurchaseMessage = (tickets, artistName = null) => {
  // Calculate total tickets
  const totalTickets = tickets.reduce((sum, ticket) => sum + ticket.count, 0);

  // Map tickets to readable strings
  const ticketStrings = tickets.map(
    (ticket) =>
      `${ticket.count} ${ticket.name} ticket${ticket.count > 1 ? "s" : ""}`
  );

  // Join with proper grammar
  let ticketList = ticketStrings.join(" and ");

  // Create the success message with optional artist name
  const successMessage = artistName
    ? `Congrats! You successfully purchased ${ticketList} for ${artistName}'s event!`
    : `Congrats! You successfully purchased ${ticketList}!`;

  const referralMessage =
    "Did you know you can earn money from sharing referral links of an event?";

  return {
    purchaseMessage: successMessage,
    additionalMessage: referralMessage,
    totalTickets,
  };
};

export const appendPurchaseMessages = (
  purchasedTickets,
  messages,
  setMessages,
  artistName = null
) => {
  const { purchaseMessage, additionalMessage, totalTickets } =
    formatTicketPurchaseMessage(purchasedTickets, artistName);

  setMessages([
    ...messages,
    {
      type: "bot",
      content: purchaseMessage,
      isTicketPurchase: true,
      totalTickets,
    },
    {
      type: "bot",
      content: additionalMessage,
      isTicketPurchase: true,
      totalTickets,
    },
  ]);
};

export const handleShareTicketReferral = async (inviteLink) => {
  if (!navigator) {
    return toast.error("Sharing is not supported by your browser");
  }

  // Check if the browser supports sharing links
  if (!navigator?.canShare || !navigator?.share) {
    return toast.error(
      "Woops, looks like your browser doesn't support sharing links."
    );
  }

  const url = inviteLink;
  const title = "Refer event tickets";
  const text = "Use my referral code";

  const shareData = { title, text, url };

  // Check if the data can be shared by the browser
  if (!navigator.canShare(shareData)) {
    return toast.error(
      "The data you're trying to share is not supported by your browser"
    );
  }

  // Share the data using the browser's share API
  await navigator.share(shareData).catch((err) => console.warn(err));
};

export const generateSessionId = () => {
  const timestamp = Date.now();
  const random = Math.random().toString(36).substring(2, 8);
  return `${timestamp}-${random}`;
};

export const generateTransferPrompt = ({
  from,
  to,
  contractAddress,
  tokenId,
  userId,
}) => {
  if (!from || !to || !contractAddress || !tokenId || !userId) {
    return "";
  }

  const prompt = `Can you transfer a token for me? Use the agent_kit tool. Use the following and put it in an object: prompt: 'Can you transfer a token for me? This is an ERC721 token, from: ${from}, to: ${to}, contractAddress: ${contractAddress}, tokenId: ${tokenId}, use the transfer_erc721 tool.', userId: ${userId}`;

  return prompt;
};
