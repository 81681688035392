import PropTypes from "prop-types";
import { Section, Container, ModalContainer } from "./BuyTicket-Styles";
import { useBuyTicketStore } from "./buyTicketStore";
import { useCheckoutStore } from "../Checkout/checkoutStore";
import { useEffect } from "react";
import TopNavV2 from "../TopNavV2";
import EventDetails from "./EventDetails";
import Tickets from "./Tickets";
import Summary from "./Summary";
import { useTicketsStatusQuery } from "./hooks/buyTicketQueries";
import useAuth from "../../hooks/useAuth";
import { useAlchemyQuery } from "../../routes/Profile/hooks/profileQueries";
import { useHandleReferralVisit } from "../PromotionPool/hooks/promotionPoolHooks";
import { useSearchParams } from "react-router-dom";

export default function BuyTicket({
  event,
  ticketsData,
  isEventsLoading,
  isModal = false,
}) {
  const { id: eventId, headline_artist_id } = event || {};
  const { contract } = ticketsData || {};
  const { loggedInUser } = useAuth();
  const {
    reset: resetBuyTicketStore,
    setEvent,
    setContract,
    resetTicketsSelected,
  } = useBuyTicketStore();
  const { reset: resetCheckoutStore, resetTotal } = useCheckoutStore();
  const { data: status, isLoading: statusLoading } = useTicketsStatusQuery({
    userId: loggedInUser?.id,
    eventId: eventId,
    artistId: headline_artist_id,
  });
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("referralCode");

  const isAllLoading = isEventsLoading || statusLoading;

  useAlchemyQuery();

  useHandleReferralVisit(loggedInUser?.id, referralCode);

  useEffect(() => {
    resetTicketsSelected();
    resetTotal();
  }, []);

  useEffect(() => {
    if (!event || !contract) return;

    setEvent(event);
    setContract(contract);
  }, [event, contract]);

  const handleBack = () => {
    resetBuyTicketStore();
    resetCheckoutStore();
  };

  if (isModal) {
    return (
      <ModalContainer>
        <Container>
          <EventDetails event={event} />
          <Tickets
            data={ticketsData}
            status={status}
            isLoading={isAllLoading}
          />
        </Container>
        <Summary status={status} isLoading={isAllLoading} />
      </ModalContainer>
    );
  }

  return (
    <Section>
      <TopNavV2 title="Buy ticket" onBack={handleBack} isSticky isBlurred />
      <Container>
        <EventDetails event={event} isLoading={isEventsLoading} />
        <Tickets data={ticketsData} status={status} isLoading={isAllLoading} />
      </Container>
      <Summary status={status} isLoading={isAllLoading} />
    </Section>
  );
}

BuyTicket.propTypes = {
  event: PropTypes.object,
  ticketsData: PropTypes.object,
  isEventsLoading: PropTypes.bool,
  isModal: PropTypes.bool,
};
