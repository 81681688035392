import styled from "styled-components";

export const Container = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.blueSolid};
  padding: 24px;
`;

export const FeeContainer = styled.div`
  display: flex;
  gap: 4px;
`;
