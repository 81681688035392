import { useQuery } from "@tanstack/react-query";
import { getDigitalCollectibleById } from "../../api/collectibles";

export const useDigitalCollectibleQuery = ({ collectibleId }) => {
  return useQuery({
    queryKey: ["digitalCollectible", { collectibleId }],
    queryFn: async () => {
      const collectible = await getDigitalCollectibleById(collectibleId);

      return collectible;
    },
  });
};
