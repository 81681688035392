import { PAYMENT_METHODS } from "../../../constants/constants";
import { BodyText, ButtonRect } from "../../../GlobalStyles";
import { usePrimaryWallet } from "../../../hooks/usePrimaryWallet";
import { theme } from "../../../utils/theme";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import { useCheckoutStore } from "../checkoutStore";
import { useTicketCheckout } from "../hooks/useTicketCheckout";
import { useWertCheckout } from "../hooks/useWertCheckout";
import { Container } from "./CheckoutButton-Styles";
import Coins from "../../../assets/svg/coins.svg?react";
import { useChainId } from "wagmi";
import { isProd } from "../../../utils/utils";
import { base, baseSepolia } from "@alchemy/aa-core";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";
import { useWertCollectiblesCheckout } from "../hooks/useWertCollectiblesCheckout";
import { useCollectiblesCheckout } from "../hooks/useCollectiblesCheckout";

const { colors } = theme;
const { WALLET, CREDIT_CARD } = PAYMENT_METHODS;
export default function CheckoutButton() {
  const { primaryWallet } = usePrimaryWallet();
  const { ticketsSelected, isOpen: ticketsOpen } = useBuyTicketStore();
  const { collectiblesSelected, isOpen: collectiblesOpen } =
    useBuyCollectibleStore();
  const {
    paymentMethod,
    isMinting,
    total,
    error,
    hasEnoughWalletBalance,
    setError,
  } = useCheckoutStore();
  const { buyWihoutPaymaster, buyWithPaymaster, buyForFree } =
    useTicketCheckout();
  const {
    buyWithPaymaster: buyCollectiblesWithPaymaster,
    buyWihoutPaymaster: buyCollectiblesWihoutPaymaster,
    buyForFree: buyCollectiblesForFree,
  } = useCollectiblesCheckout();
  const { buyWithCard } = useWertCheckout();
  const { buyWithCard: buyCollectiblesWithCard } =
    useWertCollectiblesCheckout();
  const chainId = useChainId();

  const free =
    total === 0 &&
    (ticketsOpen
      ? ticketsSelected?.length > 0
      : collectiblesSelected?.length > 0);

  const correctChain = isProd
    ? chainId === base.id
    : chainId === baseSepolia.id;

  const disabledBuy =
    isMinting ||
    (!paymentMethod && !free) ||
    (!hasEnoughWalletBalance && !free && paymentMethod === WALLET) ||
    (!primaryWallet?.connected && paymentMethod === WALLET) ||
    (!correctChain && paymentMethod === WALLET);

  const buttonText = error
    ? "Try again"
    : isMinting
      ? "Processing..."
      : free && ticketsOpen
        ? "Claim tickets"
        : free && collectiblesOpen
          ? "Claim collectibles"
          : "Pay";

  const handleBuyTickets = () => {
    if (paymentMethod === WALLET) {
      primaryWallet?.type === "smart_wallet"
        ? buyWithPaymaster()
        : buyWihoutPaymaster();
    } else if (paymentMethod === CREDIT_CARD) buyWithCard();
    else if (free) buyForFree();
  };

  const handleBuyCollectibles = () => {
    if (paymentMethod === WALLET) {
      primaryWallet?.type === "smart_wallet"
        ? buyCollectiblesWithPaymaster()
        : buyCollectiblesWihoutPaymaster();
    } else if (paymentMethod === CREDIT_CARD) buyCollectiblesWithCard();
    else if (free) {
      buyCollectiblesForFree();
    }
  };

  const handleBuy = () => {
    setError(null);

    if (ticketsOpen && !collectiblesOpen) handleBuyTickets();
    else if (collectiblesOpen && !ticketsOpen) handleBuyCollectibles();
  };

  return (
    <Container>
      {error && <BodyText color={colors.red}>{error}</BodyText>}
      <ButtonRect
        style={{
          background: colors.green,
          border: "none",
          color: colors.black,
        }}
        disabled={disabledBuy}
        onClick={handleBuy}
      >
        <Coins height={16} width={16} stroke={colors.black} />
        {buttonText}
      </ButtonRect>
    </Container>
  );
}
