import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { BodyText, FooterText, IconWithText } from "../../../GlobalStyles";
import {
  Container,
  DescriptionItemsContainer,
  UserIcon,
} from "../../../routes/Collectible/Collectible-Styles";
import OpenseaIcon from "../../../assets/svg/opensea.svg?react";
import MagicEdenIcon from "../../../assets/svg/magic-eden.svg?react";
import Music from "../../../assets/svg/music.svg?react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { isProd } from "../../../utils/utils";
import { APPLICATION_ICONS } from "../../../constants/constants";
import { useMemo } from "react";

export default function CollectibleUrls({
  contractAddress,
  isLoading,
  platform,
}) {
  const openseaUrl = isProd
    ? `https://opensea.io/assets/base/${contractAddress}`
    : `https://testnets.opensea.io/assets/base-sepolia/${contractAddress}`;

  const magicEdenUrl = isProd
    ? `https://magiceden.io/collections/base/${contractAddress}`
    : null;

  const header = platform ? "Buy collectible" : "Trade your collectible";

  const icon = useMemo(
    () =>
      APPLICATION_ICONS.find((app) => app.name === platform?.platform)?.icon,
    [platform]
  );

  if (isLoading) {
    return (
      <Container style={{ gap: "12px" }}>
        <SkeletonElement height="17px" width="150px" />
        <DescriptionItemsContainer style={{ margin: 0 }}>
          <IconWithText>
            <SkeletonElement height="24px" width="100%" />
          </IconWithText>
          <IconWithText>
            <SkeletonElement height="24px" width="100%" />
          </IconWithText>
        </DescriptionItemsContainer>
      </Container>
    );
  }

  return (
    <Container style={{ gap: "12px" }}>
      <BodyText weight="600">{header}</BodyText>
      <DescriptionItemsContainer style={{ margin: 0 }}>
        {platform && (
          <IconWithText
            style={{ gap: "8px" }}
            as={Link}
            to={platform?.platform_url}
            target="_blank"
          >
            {icon ? (
              <UserIcon
                src={icon}
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            ) : (
              <Music height={16} width={16} stroke="white" />
            )}
            <FooterText
              style={{
                textTransform: "capitalize",
              }}
            >
              {platform?.platform}
            </FooterText>
          </IconWithText>
        )}
        {magicEdenUrl ? (
          <IconWithText
            style={{ gap: "8px" }}
            as={Link}
            to={magicEdenUrl}
            target="_blank"
          >
            <MagicEdenIcon />
            <FooterText>Magic Eden</FooterText>
          </IconWithText>
        ) : null}
        <IconWithText
          style={{ gap: "8px" }}
          as={Link}
          to={openseaUrl}
          target="_blank"
        >
          <OpenseaIcon />
          <FooterText>Opensea</FooterText>
        </IconWithText>
      </DescriptionItemsContainer>
    </Container>
  );
}

CollectibleUrls.propTypes = {
  contractAddress: PropTypes.string,
  isLoading: PropTypes.bool,
  platform: PropTypes.object,
};
