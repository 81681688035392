import moment from "moment";
import { ButtonRectSm, FooterText } from "../../../GlobalStyles";
import useGlobalModal from "../../../hooks/useGlobalModal";
import TicketTransfer from "../../../routes/TicketTransfer";
import { useTicketTransferStore } from "../../../routes/TicketTransfer/stores/ticketTransferStore";
import { setDelay } from "../../../utils/utils";
import { Container } from "./TicketTransferSummary-Styles";
import { theme } from "../../../utils/theme";
import Plane from "../../../assets/svg/plane.svg?react";
import useAuth from "../../../hooks/useAuth";
import { generateTransferPrompt } from "../../../utils/milo";

export default function TicketTransferSummary() {
  const { loggedInUser } = useAuth();
  const {
    ticket,
    selectedUsers,
    setIsModal,
    setIsSelectingTickets,
    setPrompt,
  } = useTicketTransferStore();
  const { toggleSheetModal, setSheetModalContent } = useGlobalModal();
  const { headline_artist, date, contract_address, token_id } = ticket || {};

  const handleSelect = async () => {
    setIsModal(true);
    setIsSelectingTickets(false);
    toggleSheetModal();
    await setDelay(1000);

    setSheetModalContent(<TicketTransfer />);
    toggleSheetModal();
  };

  const handleTransfer = () => {
    const { id, mpc_address } = loggedInUser || {};
    const { receiving_wallet } = selectedUsers?.[0] || {};

    const prompt = generateTransferPrompt({
      from: mpc_address,
      to: receiving_wallet,
      contractAddress: contract_address,
      tokenId: token_id,
      userId: id,
    });

    setPrompt(prompt);
    toggleSheetModal();
  };

  if (selectedUsers?.length > 0) {
    return (
      <Container>
        <FooterText secondary truncate>
          1 <FooterText>{headline_artist}</FooterText> ticket on{" "}
          <FooterText color={theme.colors.green}>
            {moment(date).format("ddd DD MMM")}
          </FooterText>{" "}
          for @<FooterText>{selectedUsers[0]?.username}</FooterText>
        </FooterText>
        <ButtonRectSm onClick={handleTransfer} green disabled={!ticket}>
          <Plane height={16} width={16} stroke="black" strokeWidth={1.5} />
          Transfer
        </ButtonRectSm>
      </Container>
    );
  }

  return (
    <Container>
      <FooterText secondary={!ticket}>
        You can only transfer 1 ticket at a time.
      </FooterText>
      <ButtonRectSm onClick={handleSelect} green disabled={!ticket}>
        Select
      </ButtonRectSm>
    </Container>
  );
}
