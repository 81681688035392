import PropTypes from "prop-types";
import { AvatarPlaceholder } from "../../../constants/constants";
import { BodyText, ButtonSm, IconWithText } from "../../../GlobalStyles";
import { Container, UserImage } from "./TicketTransferUserItem-Styles";
import WalletIcon from "../../../assets/svg/wallet.svg?react";
import Check from "../../../assets/svg/check-green.svg?react";
import { theme } from "../../../utils/theme";
import { trimAddress } from "../../../utils/utils";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { useMemo } from "react";
import { useTicketTransferStore } from "../../../routes/TicketTransfer/stores/ticketTransferStore";

export default function TicketTransferUserItem({ user, isLoading }) {
  const { id, avatar, username, receiving_wallet } = user || {};
  const { selectedUsers, toggleSelectedUser } = useTicketTransferStore();

  const isSelected = useMemo(
    () => selectedUsers.some((u) => u.id === id),
    [selectedUsers, id]
  );

  const isDisabled = useMemo(
    () => selectedUsers.length && !isSelected,
    [selectedUsers, isSelected]
  );

  const handleSelect = () => {
    toggleSelectedUser(user);
  };

  if (isLoading) {
    return (
      <Container>
        <IconWithText>
          <SkeletonElement height="40px" width="40px" radius="50%" />
          <SkeletonElement height="20px" width="120px" />
        </IconWithText>
        <IconWithText>
          <SkeletonElement height="20px" width="100px" />
          <SkeletonElement height="30px" width="30px" radius="10px" />
        </IconWithText>
      </Container>
    );
  }

  return (
    <Container
      onClick={handleSelect}
      style={{
        background: isSelected ? theme.colors.blue : "transparent",
        opacity: isDisabled ? 0.6 : 1,
        pointerEvents: isDisabled ? "none" : "auto",
      }}
    >
      <IconWithText
        style={{
          overflow: "hidden",
        }}
      >
        <UserImage
          src={avatar || AvatarPlaceholder}
          onError={(e) => (e.target.src = AvatarPlaceholder)}
        />
        <BodyText truncate>{username}</BodyText>
      </IconWithText>
      <IconWithText>
        <WalletIcon height={14} width={14} stroke={theme.colors.white} />
        <BodyText
          secondary
          style={{
            minWidth: 80,
            textAlign: "right",
          }}
        >
          {trimAddress(receiving_wallet)}
        </BodyText>
        <ButtonSm
          style={{
            border: isSelected && `1px solid ${theme.colors.green}`,
          }}
        >
          {isSelected && <Check height={16} width={16} />}
        </ButtonSm>
      </IconWithText>
    </Container>
  );
}

TicketTransferUserItem.propTypes = {
  user: PropTypes.object,
  isLoading: PropTypes.bool,
};
