import {
  Button,
  Divider,
  Drawer,
  Group,
  Image,
  Stack,
  Text,
} from "@mantine/core";
import PropTypes from "prop-types";
import { EventBox } from "../EventBox";
import { BodyText } from "../../../GlobalStyles";
import { useNavigate } from "react-router-dom/dist";
import useAuth from "../../../hooks/useAuth";
import {
  useGenerateTicketReferral,
  usePromoterDetails,
} from "../../PromotionPool/hooks/promotionPoolHooks";
import { handleShareTicketReferral } from "../../../utils/milo";

export const PromoteToEarnDrawer = ({ opened = false, close, event }) => {
  const navigate = useNavigate();
  const { loggedInUser } = useAuth();

  const generateTicketReferralQuery = useGenerateTicketReferral({
    eventId: event?.id ?? undefined,
    userId: loggedInUser?.id ?? undefined,
  });

  const generateTicketReferral = generateTicketReferralQuery?.data || {};

  const promoterDetailsQuery = usePromoterDetails({
    eventId: event?.id,
    referralCode: generateTicketReferral.referral ?? undefined,
  });

  const promoterDetails = promoterDetailsQuery?.data?.[0] || {};

  const link = `/events/${event?.id}?date=${event?.date}&referralCode=${generateTicketReferral.referral}&openDrawer=true`;

  return (
    <Drawer
      opened={opened}
      onClose={close}
      withCloseButton={false}
      styles={{
        content: {
          padding: "0px",
          backgroundColor: "#11111F",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          minHeight: "fit-content",
          maxHeight: "90svh",
        },
        body: {
          padding: "24px",
          backgroundColor: "#11111F",
        },
      }}
      position="bottom"
    >
      <Stack
        style={{
          backgroundColor: "#11111F",
        }}
        justify="center"
        align="center"
        gap={0}
      >
        <Divider
          w="60px"
          size="4px"
          color="#FFFFFF26"
          style={{
            marginBottom: "24px",
          }}
          onClick={close}
        />

        <Image
          src="/images/milo/earn.svg"
          alt="earn"
          style={{
            width: 32,
            height: 32,
            marginBottom: 16,
          }}
        />
        <Text
          style={{
            fontFamily: "Druk",
            fontSize: "31.25px",
            fontWeight: "900",
            lineHeight: "100%",
            letterSpacing: "0.04em",
            textAlign: "center",
            color: "white",
            marginBottom: 24,
          }}
        >
          PROMOTE TO EARN
        </Text>
        <div
          style={{
            marginBottom: 24,
          }}
        >
          <EventBox
            id={event?.id}
            artistName={
              event?.artistName ?? event?.headline_artist ?? "The Weeknd"
            }
            headlineArtistId={event?.headline_artist_id ?? "0213123"}
            venue={event?.venue ?? "The O2"}
            date={event?.date ?? "2025-01-01"}
            imageUrl={
              event?.imageUrl ??
              event?.image ??
              promoterDetails?.image ??
              "/eventplaceholder.svg"
            }
            isDrawer={true}
          />
        </div>
        <BodyText
          secondary
          style={{
            textAlign: "center",
            marginBottom: 24,
          }}
        >
          Earn a percentage of this event’s revenue when fans buy a ticket
          through your unique referral link.
        </BodyText>
        <Group
          style={{
            marginBottom: 24,
          }}
        >
          <Button
            style={{
              width: "192.5px",
              height: "37px",
              gap: "12px",
              borderRadius: "10px",
              backgroundColor: "#6EDBBB",
              color: "white",
            }}
            onClick={() => handleShareTicketReferral(link)}
          >
            <Group>
              <Image src="/images/milo/share.svg" alt="copy" />
              <Text
                style={{
                  fontFamily: "Suisse Intl",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "100%",
                  color: "black",
                }}
              >
                Share referral link
              </Text>
            </Group>
          </Button>
          <BodyText
            style={{
              color: "#6EDBBB",
            }}
          >
            {promoterDetails?.total_referral_mints_user ?? 0}{" "}
            <BodyText
              span
              style={{
                color: "white",
              }}
            >
              referred
            </BodyText>
          </BodyText>
        </Group>
        <Button
          style={{
            width: "138px",
            height: "32px",
            padding: "0px",
            borderRadius: "10px",
            backgroundColor: "#2D2D52",
          }}
          onClick={() =>
            navigate(`/promotion-pool/${event?.id}?date=${event?.date}`)
          }
        >
          <Text
            style={{
              fontFamily: "Suisse Intl",
              fontSize: "12px",
              fontWeight: "600",
              lineHeight: "100%",

              textDecorationSkipInk: "none",
              color: "white",
            }}
          >
            See promoter pool
          </Text>
        </Button>
      </Stack>
    </Drawer>
  );
};

PromoteToEarnDrawer.propTypes = {
  opened: PropTypes.bool,
  close: PropTypes.func,
  event: PropTypes.object,
};
