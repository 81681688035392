import PropTypes from "prop-types";
import {
  BodyText,
  ButtonRect,
  FooterText,
  Header,
} from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { Container, FeeContainer } from "./Summary-Styles";
import TwoTickets from "../../../assets/svg/two-tickets.svg?react";
import { formatPrice, setDelay } from "../../../utils/utils";
import useGlobalModal from "../../../hooks/useGlobalModal";
import Checkout from "../../Checkout";
import { useCheckoutStore } from "../../Checkout/checkoutStore";
import { useBuyTicketStore } from "../buyTicketStore";

const { colors } = theme;

export default function Summary({ status, isLoading }) {
  const { ticketsSelected, isModal } = useBuyTicketStore();
  const { momentifyFee, ticketsTotal, isMinting, total } = useCheckoutStore();
  const { setSheetModalContent, toggleSheetModal, setSheetModalBackdropStyle } =
    useGlobalModal();
  const { maxCount, userMints, soldOut, success } = status || {};

  const formattedPrice = formatPrice(ticketsTotal);

  const disabled =
    isLoading ||
    soldOut ||
    !success ||
    userMints >= maxCount ||
    isMinting ||
    (total <= 0 && !ticketsSelected?.length);

  const handleCheckout = async () => {
    if (isModal) {
      toggleSheetModal();
      await setDelay(1000);
    }

    setSheetModalContent(<Checkout />);
    setSheetModalBackdropStyle({
      backdropFilter: "blur(10px)",
    });
    toggleSheetModal();
  };

  return (
    <Container>
      <FeeContainer style={{ alignItems: "center", gap: "12px" }}>
        <BodyText bold>Total</BodyText>
        <FeeContainer style={{ alignItems: "flex-end", gap: "0px" }}>
          <Header
            style={{
              fontFamily: theme.fontStyles.suisse,
              fontWeight: 600,
            }}
          >
            ${formattedPrice[0]}
          </Header>
          <BodyText
            style={{
              marginBottom: "1px",
              fontSize: "16px",
              fontFamily: theme.fontStyles.suisse,
            }}
            bold
          >
            .{formattedPrice[1] ?? "00"}
          </BodyText>
          <FooterText color={colors.grey} style={{ marginLeft: "8px" }}>
            +${formatPrice(momentifyFee, false)} fee
          </FooterText>
        </FeeContainer>
      </FeeContainer>
      <ButtonRect
        green
        style={{ padding: "0 16px" }}
        disabled={disabled}
        onClick={handleCheckout}
      >
        <TwoTickets stroke={colors.black} height={14} width={14} />
        <FooterText weight="600" color={colors.black}>
          Buy
        </FooterText>
      </ButtonRect>
    </Container>
  );
}

Summary.propTypes = {
  status: PropTypes.object,
  isLoading: PropTypes.bool,
};
