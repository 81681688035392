import { useNavigate, useParams } from "react-router-dom";
import TicketItem from "../../components/Tickets/TicketItem";
import TopNavV2 from "../../components/TopNavV2";
import { useGetMintedTicket } from "./hooks/ticketTransferQueries";
import {
  Container,
  Content,
  ModalContainer,
  TicketContainer,
} from "./TicketTransfer-Styles";
import TicketTransferSearch from "../../components/TicketTransfer/TicketTransferSearch";
import TicketTransferFooter from "../../components/TicketTransfer/TicketTransferFooter";
import { useTicketTransferStore } from "./stores/ticketTransferStore";
import { useEffect } from "react";
import { useAlchemyQuery } from "../Profile/hooks/profileQueries";
import { ButtonRectSm } from "../../GlobalStyles";
import SelectTicketTransfer from "../../components/Modal/SelectTicketTransfer";
import useGlobalModal from "../../hooks/useGlobalModal";
import { setDelay } from "../../utils/utils";
import useAuth from "../../hooks/useAuth";
import { theme } from "../../utils/theme";
import TicketTransferSummary from "../../components/TicketTransfer/TicketTransferSummary";

export default function TicketTransfer() {
  const { profileId, mintId } = useParams();
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const { setSheetModalContent, toggleSheetModal } = useGlobalModal();
  const { selectedUsers, setTicket, ticket, reset, isModal, setIsModal } =
    useTicketTransferStore();
  const { data, isLoading } = useGetMintedTicket({
    userId: isModal ? loggedInUser?.id : profileId,
    mintId: isModal ? ticket?.id : mintId,
  });

  useAlchemyQuery();

  const isTransferable = selectedUsers?.length > 0;

  useEffect(() => {
    if (profileId || mintId) setIsModal(false);

    if (!data || isModal) return;

    reset();
    setTicket({ ...data, count: 1 });
  }, [data, setTicket, reset]);

  const handleBack = () => {
    if (isModal) return handleChangeTicket();

    reset();

    if (!data) return navigate(-1);

    const { event_id, type } = data || {};
    navigate(`/profile/${profileId}/tickets/${event_id}/type/${type}`);
  };

  const handleChangeTicket = async () => {
    toggleSheetModal();

    await setDelay(1000);

    setSheetModalContent(<SelectTicketTransfer />);
    toggleSheetModal();
  };

  if (isModal) {
    return (
      <>
        <TopNavV2
          title="Transfer Ticket"
          onBack={handleBack}
          isSticky
          isBlurred
          content={
            <ButtonRectSm onClick={handleChangeTicket}>
              Change ticket
            </ButtonRectSm>
          }
          style={{
            paddingTop: 0,
            paddingBottom: 4,
            background: theme.colors.background,
          }}
        />
        <ModalContainer>
          <Content
            style={{ padding: `0 20px ${isTransferable ? "0" : "16px"}` }}
          >
            <TicketContainer>
              <TicketItem
                ticket={{ ...data, count: 1 }}
                isLoading={isLoading}
              />
            </TicketContainer>
            <TicketTransferSearch isLoading={isLoading} />
          </Content>
          {isTransferable && <TicketTransferSummary />}
        </ModalContainer>
      </>
    );
  }

  return (
    <Container>
      <TopNavV2
        title="Transfer Ticket"
        onBack={handleBack}
        isSticky
        isBlurred
      />
      <Content style={{ padding: `0 20px ${isTransferable ? "0" : "16px"}` }}>
        <TicketItem ticket={{ ...data, count: 1 }} isLoading={isLoading} />
        <TicketTransferSearch isLoading={isLoading} />
      </Content>
      {isTransferable && <TicketTransferFooter />}
    </Container>
  );
}
