import { Group, Stack, Text } from "@mantine/core";
import PropTypes from "prop-types";
import USDC from "../../assets/svg/usdc.svg?react";

export const ReferralGains = ({
  numberReferrals,
  totalReferrals,
  totalValue,
  poolPercentage,
}) => {
  const poolCommissions = isNaN(totalValue * (poolPercentage / 100))
    ? 0
    : (totalValue * (poolPercentage / 100)).toFixed(2);

  const poolCommissionsPerReferral = isNaN(
    poolCommissions / (totalReferrals || 1)
  )
    ? 0
    : (poolCommissions / (totalReferrals || 1)).toFixed(2);

  const totalEarnings = isNaN(poolCommissionsPerReferral * numberReferrals)
    ? 0
    : (poolCommissionsPerReferral * numberReferrals).toFixed(2);

  return (
    <Stack
      align="center"
      justify="center"
      gap={12}
      style={{
        background:
          "linear-gradient(122.5deg, rgba(180, 180, 180, 0.2) -11.54%, rgba(79, 79, 79, 0.2) -11.53%, rgba(255, 255, 255, 0.2) 24.12%, rgba(175, 175, 175, 0.2) 97.15%)",
        borderRadius: "12px",
        padding: "16px",
      }}
    >
      <Text
        style={{
          fontFamily: "Suisse Intl",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "100%",
          color: "white",
        }}
      >
        Your potential referral earning
      </Text>
      <Group>
        <Text
          style={{
            fontFamily: "Suisse Intl",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "100%",
            color: "#99999D",
          }}
        >
          {numberReferrals}
        </Text>
        <Group gap={4}>
          <Text
            style={{
              fontFamily: "Suisse Intl",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "100%",
              color: "white",
            }}
          >
            ${totalEarnings}
          </Text>
          <USDC
            width={12}
            height={12}
            style={{ bottom: 0.5, position: "relative" }}
          />
        </Group>
      </Group>
    </Stack>
  );
};

ReferralGains.propTypes = {
  numberReferrals: PropTypes.number.isRequired,
  totalReferrals: PropTypes.number.isRequired,
  totalValue: PropTypes.number.isRequired,
  poolPercentage: PropTypes.number.isRequired,
};
