import { useReadContracts } from "wagmi";
import { BodyText, Header } from "../../../GlobalStyles";
import { BalanceContainer, Container } from "./WalletCardBalance-Styles";
import PropTypes from "prop-types";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import USDCIcon from "../../../assets/svg/usdc.svg?react";
import { erc20Abi, formatUnits } from "viem";
import { formatPrice, isProd } from "../../../utils/utils";
import { base, baseSepolia } from "viem/chains";
import {
  USDC_ADDRESS,
  USDC_ADDRESS_SEPOLIA,
} from "../../../constants/constants";
import { useEffect, useMemo } from "react";
import { theme } from "../../../utils/theme";
import { useMiloStore } from "../../Milo/stores/miloStore";
import { usePrimaryWallet } from "../../../hooks/usePrimaryWallet";

export default function WalletCardBalance({ address, message }) {
  const USDC = isProd ? USDC_ADDRESS : USDC_ADDRESS_SEPOLIA;
  const chainId = isProd ? base.id : baseSepolia.id;
  const { messages, setMessages } = useMiloStore();
  const { primaryWallet } = usePrimaryWallet();

  const { data, isLoading, isFetching } = useReadContracts({
    allowFailure: true,
    contracts: [
      {
        address: USDC,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: [address],
        chainId,
      },
      {
        address: USDC,
        abi: erc20Abi,
        functionName: "decimals",
        chainId,
      },
    ],
  });

  const { 0: balance, 1: decimals } = data || {};

  const balanceArray = useMemo(() => {
    if (isFetching && !message) return ["0", "00"];

    if (message?.wallet?.balance) {
      return formatPrice(message?.wallet?.balance);
    }

    if (balance?.status === "success" && decimals?.status === "success") {
      const formattedBalance = formatUnits(balance.result, decimals.result);
      return formatPrice(formattedBalance);
    }
    return ["0", "00"];
  }, [message, balance, decimals, isFetching]);

  useEffect(() => {
    if (!message || message?.wallet) return;

    // Add wallet object to last message
    const newMessages = [...messages];
    newMessages[newMessages.length - 1] = {
      ...newMessages[newMessages.length - 1],
      wallet: {
        ...primaryWallet,
        balance: `${balanceArray[0]}.${balanceArray[1]}`,
      },
    };
    setMessages(newMessages);
  }, [message, isFetching]);

  if (isLoading || (isFetching && !message?.wallet?.balance)) {
    return <SkeletonElement height="30px" width="120px" />;
  }

  return (
    <Container>
      <BalanceContainer>
        <Header
          style={{
            display: "flex",
            alignItems: "flex-end",
            fontFamily: theme.fontStyles.suisse,
            fontWeight: 600,
          }}
        >
          ${balanceArray[0] ?? "0"}.
          <BodyText
            style={{
              marginBottom: "1px",
              fontSize: "16px",
              fontFamily: theme.fontStyles.suisse,
              fontWeight: 600,
            }}
          >
            {balanceArray[1]?.slice(0, 2) ?? "00"}
          </BodyText>
        </Header>
        <div>
          <USDCIcon height={24} width={24} />
        </div>
      </BalanceContainer>
    </Container>
  );
}

WalletCardBalance.propTypes = {
  address: PropTypes.string,
  message: PropTypes.object,
};
