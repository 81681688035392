import { Flex } from "@mantine/core";
import { ButtonRectSm } from "../../../GlobalStyles";
import useGlobalModal from "../../../hooks/useGlobalModal";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
import SelectTicketTransfer from "../../Modal/SelectTicketTransfer";
import PropTypes from "prop-types";

export default function TransferTicketButton({ onSendMessage }) {
  const { loggedInUser, login } = useMomentifyLogin();
  const { setSheetModalContent, toggleSheetModal, setSheetModalBackdropStyle } =
    useGlobalModal();

  const handleClick = () => {
    if (!loggedInUser) return login();

    setSheetModalBackdropStyle({
      backdropFilter: "blur(10px)",
    });
    setSheetModalContent(<SelectTicketTransfer />);
    toggleSheetModal();
  };

  const handleNo = (message) => {
    onSendMessage(message);
  };

  return (
    <Flex
      style={{
        gap: 16,
        margin: "0 auto",
      }}
    >
      <ButtonRectSm
        onClick={handleClick}
        primary
        style={{
          width: 80,
        }}
      >
        Yes
      </ButtonRectSm>
      <ButtonRectSm
        onClick={() => handleNo("No")}
        primary
        style={{
          width: 80,
        }}
      >
        No
      </ButtonRectSm>
    </Flex>
  );
}

TransferTicketButton.propTypes = {
  onSendMessage: PropTypes.func,
};
