import {
  Button,
  Center,
  Group,
  Image,
  Loader,
  Stack,
  Text,
} from "@mantine/core";
import { EventBox } from "../../components/Milo/EventBox";
import { useEventLoader } from "../Event/hooks/eventQueries";
import { useParams, useSearchParams } from "react-router-dom/dist";
import useAuth from "../../hooks/useAuth";
import { ProgressBar } from "../../components/PromotionPool/ProgressBar";
import { PoolDetails } from "../../components/PromotionPool/PoolDetails";
import { ReferralGains } from "../../components/PromotionPool/ReferralGains";
import {
  useGenerateTicketReferral,
  useHandleReferralVisit,
  usePromoterDetails,
  useTicketBalance,
} from "../../components/PromotionPool/hooks/promotionPoolHooks";
import BackButton from "../../components/BackButton";

import { handleShareTicketReferral } from "../../utils/milo";

const PromotionPool = () => {
  const { eventId } = useParams();
  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");
  const { loggedInUser } = useAuth();

  const eventLoaderQuery = useEventLoader({
    eventId: eventId,
    date: date,
    userId: 1030 ?? loggedInUser?.id,
  });

  const generateTicketReferralQuery = useGenerateTicketReferral({
    eventId: eventId,
    userId: 1030 ?? loggedInUser?.id,
  });

  const generateTicketReferral = generateTicketReferralQuery?.data || {};

  const promoterDetailsQuery = usePromoterDetails({
    eventId: eventId,
    referralCode: generateTicketReferral.referral ?? undefined,
  });

  const event = eventLoaderQuery.data || {};
  const promoterDetails = promoterDetailsQuery?.data?.[0] || {};

  const ticketBalanceQuery = useTicketBalance({
    walletAddress: promoterDetails?.wallet_address,
  });

  const ticketBalance = ticketBalanceQuery?.data || {};

  const link = `/events/${event?.id}?date=${event?.date}&referralCode=${generateTicketReferral.referral}&openDrawer=true`;

  useHandleReferralVisit(loggedInUser?.id, generateTicketReferral.referral);

  if (
    eventLoaderQuery.isLoading ||
    promoterDetailsQuery.isLoading ||
    generateTicketReferralQuery.isLoading ||
    ticketBalanceQuery.isLoading
  ) {
    return (
      <Center
        w="100vw"
        h="100%"
        style={{
          flexGrow: 1,
        }}
      >
        <Loader size={60} color="#6EDBBB" mb={240} />
      </Center>
    );
  }

  return (
    <Stack
      style={{
        width: "100%",
        height: "100%",
        flexGrow: 1,
        padding: "16px 20px",
      }}
      gap={0}
    >
      <Group gap={0}>
        <BackButton
          style={{
            padding: 0,
            marginBottom: "19px",
          }}
        />
        <Text
          style={{
            fontFamily: "Suisse Intl",
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "100%",
            color: "white",
            marginBottom: "16px",
          }}
        >
          Promote to earn
        </Text>
      </Group>

      <Text
        style={{
          fontFamily: "Suisse Intl",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "100%",

          color: "#E6E6E6",
          marginBottom: "16px",
        }}
      >
        Earn by sharing with friends!
      </Text>
      <div
        style={{
          marginBottom: "23px",
        }}
      >
        <EventBox
          id={event?.id}
          artistName={event?.headline_artist ?? "The Weeknd"}
          headlineArtistId={event?.headline_artist_id ?? "0213123"}
          venue={event?.venue ?? "The O2"}
          date={event?.date ?? "2025-01-01"}
          imageUrl={
            event?.image ?? promoterDetails?.image ?? "/eventplaceholder.svg"
          }
          isDrawer={true}
          isPromotionPool={true}
        />
      </div>

      <div
        style={{
          marginBottom: "24px",
        }}
      >
        <ProgressBar
          totalSales={promoterDetails.total_mints}
          totalTickets={promoterDetails.total_tickets}
          totalValue={parseInt(ticketBalance.balance)}
        />
      </div>

      <div
        style={{
          marginBottom: "16px",
        }}
      >
        <PoolDetails
          poolPercentage={promoterDetails.pool_percentage}
          totalValue={parseInt(ticketBalance.balance)}
          totalReferrals={promoterDetails.total_referral_mints}
        />
      </div>

      <div
        style={{
          marginBottom: "16px",
        }}
      >
        <ReferralGains
          numberReferrals={promoterDetails.total_referral_mints_user}
          poolPercentage={promoterDetails.pool_percentage}
          totalValue={parseInt(ticketBalance.balance)}
          totalReferrals={promoterDetails.total_referral_mints}
        />
      </div>

      <Button
        onClick={() => {
          console.log(link);
          handleShareTicketReferral(link);
        }}
        style={{
          width: "192.5px",
          height: "37px",
          gap: "12px",
          borderRadius: "10px",
          backgroundColor: "#6EDBBB",
          color: "white",
          marginLeft: "auto",
          marginRight: "auto",
          cursor: "pointer",
        }}
      >
        <Group>
          <Image src="/images/milo/share.svg" alt="copy" />
          <Text
            style={{
              fontFamily: "Suisse Intl",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "100%",
              color: "black",
            }}
          >
            Share referral link
          </Text>
        </Group>
      </Button>
    </Stack>
  );
};

export default PromotionPool;
