import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  gap: 16px;
  width: 100%;
  z-index: 5;
`;
