import { usePrimaryWallet } from "../../../hooks/usePrimaryWallet";
import WalletCardBalance from "../../Wallet/WalletCardBalance";
import WalletCardFooter from "../../Wallet/WalletCardFooter";
import WalletCardHeader from "../../Wallet/WalletCardHeader";
import { BalanceContainer, Container } from "./Wallet-Styles";
import PropTypes from "prop-types";

export default function Wallet({ message }) {
  const { primaryWallet } = usePrimaryWallet();
  const wallet = message?.wallet || primaryWallet;

  return (
    <Container>
      <WalletCardHeader wallet={{ ...wallet, primary: true }} fromMilo />
      <BalanceContainer>
        <WalletCardBalance address={wallet?.address} message={message} />
      </BalanceContainer>
      <WalletCardFooter wallet={wallet} fromMilo />
    </Container>
  );
}

Wallet.propTypes = {
  message: PropTypes.object,
};
