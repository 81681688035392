import styled from "styled-components";

export const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100dvh;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1000;
  background: ${({ theme }) => theme.colors.backgroundOverlay};
  background-color: ${({ theme }) => theme.colors.background};
  background-blend-mode: multiply;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 90vh;
`;

export const TicketContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  overflow-y: scroll;
  flex: 1;
`;
