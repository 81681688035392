import PropTypes from "prop-types";
import { BodyText, OneLineText } from "../../../GlobalStyles";
import { useMemo } from "react";
import { ArtistPlaceholder } from "../../../constants/constants";
import { Container, TicketContainer } from "./EventTickets-Styles";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import EventTicket from "../EventTicket";
import BuyTicketButton from "../../BuyTicket/BuyTicketButton";

export default function EventTickets({ event, ticketsLoading, artistTickets }) {
  const {
    headline_artist,
    artists,
    artists_lineup,
    urls: tickets,
  } = event || {};
  const { tickets: directTickets, contract } = artistTickets || {};

  const headlineArtist = useMemo(() => {
    if (artists?.length > 0) {
      return artists?.find((artist) => artist?.artist_name === headline_artist);
    } else if (artists_lineup?.length > 0) {
      return artists_lineup?.find(
        (artist) => artist?.artist_name === headline_artist
      );
    }
  }, [artists, artists_lineup, headline_artist]);

  const { artist_name, profile_img } = headlineArtist || {};

  if (ticketsLoading) {
    return (
      <Container>
        <BodyText weight="600">Tickets</BodyText>
        {Array.from({ length: 3 }).map((_, index) => (
          <SkeletonElement
            key={index}
            height="53px"
            width="100%"
            radius="10px"
          />
        ))}
      </Container>
    );
  }

  if (
    !directTickets?.length &&
    (!tickets?.length || !tickets?.[0]) &&
    !ticketsLoading
  ) {
    return null;
  }

  return (
    <Container>
      <BodyText weight="600">Tickets</BodyText>
      <Container
        style={{
          gap: 8,
        }}
      >
        {directTickets?.length > 0 && contract && (
          <TicketContainer glass>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <BodyText style={{ textWrap: "nowrap" }}>Direct from</BodyText>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <img
                  src={profile_img ?? ArtistPlaceholder}
                  alt={artist_name}
                  style={{ width: "20px", height: "20px", borderRadius: "50%" }}
                  onError={(e) => {
                    e.target.src = ArtistPlaceholder;
                  }}
                />
                <OneLineText weight="600">{artist_name}</OneLineText>
              </div>
            </div>
            <BuyTicketButton event={event} tickets={artistTickets} />
          </TicketContainer>
        )}

        {tickets &&
          tickets[0] != null &&
          tickets.map((item, index) => {
            if (!item) return;
            return <EventTicket key={index} ticket={item} event={event} />;
          })}
      </Container>
    </Container>
  );
}

EventTickets.propTypes = {
  event: PropTypes.object,
  ticketsLoading: PropTypes.bool,
  artistTickets: PropTypes.object,
};
