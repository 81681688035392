import { useLocation } from "react-router-dom";
import { FooterContainer } from "./BottomNav-Styles";
import NavItem from "./NavItem";
import { useNavitems } from "./hooks/navHooks";

export default function BottomNav() {
  const location = useLocation();
  const items = useNavitems();

  return (
    <FooterContainer
      isMomentView={
        location.pathname == "/" || location.pathname.includes("/moments")
          ? true
          : false
      }
    >
      {items.map((item) => (
        <NavItem
          key={item.text}
          icon={item.icon}
          isActive={item.isActive}
          url={item.url}
        />
      ))}
    </FooterContainer>
  );
}
