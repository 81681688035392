import { Group, Image, Text } from "@mantine/core";
import {
  generateTicketAffiliate,
  ticketImage,
} from "../../../utils/affiliates";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { upsertTicketClick } from "../../../api/events";
import useAuth from "../../../hooks/useAuth";

export default function TicketLink({ eventId, url, type }) {
  const { loggedInUser } = useAuth();
  const ticketUrl = generateTicketAffiliate({
    url,
    type,
  });

  const handleOnClick = () => {
    if (!ticketUrl || !loggedInUser || !eventId || !type) return;

    upsertTicketClick({
      userId: loggedInUser?.id,
      eventId: eventId,
      ticketType: type,
      ticketUrl: ticketUrl,
    });
  };

  return (
    <Link
      to={ticketUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleOnClick}
    >
      <Group
        style={{
          height: "32px",
          gap: "8px",
          borderRadius: "10px",
          backgroundColor: "#FFFFFF1F",
          padding: "6px 16px",
        }}
      >
        <Image
          src={ticketImage(type)}
          alt={type}
          style={{ width: 16, height: 16 }}
        />
        <Text
          style={{
            fontFamily: "Suisse Intl",
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "100%",
            textAlign: "center",
            textDecorationSkipInk: "none",
          }}
        >
          {type}
        </Text>
      </Group>
    </Link>
  );
}

TicketLink.propTypes = {
  eventId: PropTypes.any,
  url: PropTypes.string,
  type: PropTypes.string,
};
